import type { Address } from './workspace-address.interfaces'
import { Service } from '../../service'

export class WorkspaceAddressService extends Service {
  public find() {
    return this.get<Address>(`/api/workspace-manager/address`)
  }

  public update(address: Address) {
    return this.patch<Address>(`/api/workspace-manager/address`, address)
  }

  public remove() {
    return this.delete(`/api/workspace-manager/address`)
  }
}
