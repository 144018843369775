import { defineStore } from 'pinia'
import type {
  CameraStep,
  OnboardingCamera,
  OnboardingState,
  OnboardingStep
} from '@/modules/Onboarding/onboarding.interface'
import { OnboardingStepEnum } from '@/modules/Onboarding/onboarding.interface'
import type { CameraStatusType } from '@/modules/Camera/types'
import type { CameraTemp } from '@/modules/Camera/interface'

export const useOnboardingStore = defineStore('onboarding', {
  state: (): OnboardingState => ({
    steps: [],
    nextStep: OnboardingStepEnum.CAMERAS,
    currentStep: OnboardingStepEnum.WORKSPACE,
    data: {
      camera: {
        currentStep: 1,
        cameraList: [],
        selections: [],
        process: [0, 0, 0, 0],
        staticIp: '',
        verifyConfigureIp: false
      },
      payment: {
        fullName: '',
        zipCode: ''
      },
      cameraUsers: [],
      isBridgeSetupFinished: true,
      bridgeId: ''
    }
  }),

  getters: {
    camera(): OnboardingCamera {
      return this.data.camera
    },

    stepItems(): OnboardingStep[] {
      return this.steps
    },

    current(): OnboardingStepEnum {
      return this.currentStep
    },

    isComplete(): boolean {
      return this.steps.slice(0, this.steps.length - 1).every((item) => item.percent === 100)
    },

    hasPayment(): boolean {
      return !!this.data.payment.fullName
    },

    onboardingDefaultSteps() {
      return [
        {
          title: 'onboarding.steps.workspace',
          key: 'workspace',
          order: 0,
          percent: 0,
          status: true
        },
        {
          title: 'onboarding.steps.pairing',
          key: 'pairing',
          order: 1,
          percent: 0,
          status: false
        },
        {
          title: 'onboarding.steps.cameras',
          key: 'cameras',
          order: 2,
          percent: 0,
          status: false
        },
        {
          title: 'onboarding.steps.pricing',
          key: 'pricing',
          order: 3,
          percent: 0,
          status: false
        },
        {
          title: 'onboarding.steps.liveStream',
          key: 'liveStream',
          order: 5,
          percent: 100,
          status: false
        }
      ] as OnboardingStep[]
    },

    cameraConnectDefaultSteps() {
      return [
        {
          title: 'onboarding.steps.pairing',
          key: 'pairing',
          order: 0,
          percent: 0,
          status: true
        },
        {
          title: 'onboarding.steps.cameras',
          key: 'cameras',
          order: 1,
          percent: 0,
          status: false
        },
        {
          title: 'onboarding.steps.liveStream',
          key: 'liveStream',
          order: 5,
          percent: 100,
          status: false
        }
      ] as OnboardingStep[]
    },

    hasCameraTemp: (state) => (ip: string, bridgeId?: string) =>
      state.data.camera.cameraList.some(
        (item) =>
          item.cameraData.config.url === ip && (item.cameraData.bridgeId === bridgeId || !bridgeId)
      ),

    isBridgeSetupDone: (state) => state.data.isBridgeSetupFinished,

    selectedBridge: (state) => state.data.bridgeId,

    stepsDone: (state) =>
      (state.steps.reduce((acc, step) => acc + (step.percent ?? 0), 0) / state.steps.length) % 100
  },

  actions: {
    setSteps(newSteps: OnboardingStep[]) {
      this.steps = newSteps
    },

    initializeSteps(
      savedSteps: OnboardingStep[] = [],
      type: 'onboarding' | 'cameraConnect',
      isDone: boolean = false
    ): OnboardingStep[] {
      let newSteps: OnboardingStep[] = []
      if (type === 'onboarding') {
        if (savedSteps.length !== this.onboardingDefaultSteps.length) {
          newSteps = JSON.parse(JSON.stringify(this.onboardingDefaultSteps))
        } else {
          newSteps = savedSteps
        }
      } else {
        if (savedSteps.length !== this.cameraConnectDefaultSteps.length) {
          newSteps = JSON.parse(JSON.stringify(this.cameraConnectDefaultSteps))
        } else {
          newSteps = savedSteps
        }
      }
      if (isDone) {
        newSteps = newSteps.map((step) => {
          return {
            ...step,
            percent: 100
          }
        })
      }
      this.setSteps(newSteps)

      return newSteps
    },

    setCameraSubmitStatus(unique: string | undefined, status: CameraStatusType) {
      if (typeof unique !== 'undefined') {
        const camera = this.data.camera.cameraList.find((i) => i?.cameraData?.userData === unique)
        if (camera) {
          camera.status = status
        }
      }
    },

    setStepPercentage(step: OnboardingStepEnum, percentage: number) {
      if (percentage >= 0 && percentage <= 100) {
        const index = this.steps.findIndex((s) => s.key === step)
        this.steps[index].percent = percentage
      }
    },

    setCurrentStep(step: OnboardingStepEnum) {
      this.currentStep = step
    },

    setCameraCurrentStep(step: CameraStep) {
      this.data.camera.currentStep = step
    },

    changePageStep(step: OnboardingStepEnum) {
      this.currentStep = step
    },

    changeCameraStep(step: CameraStep) {
      this.data.camera.currentStep = step
    },

    addCameraTemp(cameraConfigurations: CameraTemp) {
      const hasSame = this.data.camera.cameraList.some(
        (c) => c.cameraData.userData === cameraConfigurations.cameraData.userData
      )
      if (cameraConfigurations.cameraData.config.manufacturerCode != undefined && !hasSame) {
        this.data.camera.cameraList.push(cameraConfigurations)
      }
    },

    dropCameraTemp(cameraConfigurations: CameraTemp) {
      const tempDataIndex = this.data.camera.cameraList.findIndex((item) => {
        return item.cameraData.userData === cameraConfigurations.cameraData.userData
      })
      if (tempDataIndex !== -1) {
        this.data.camera.cameraList.splice(tempDataIndex, 1, cameraConfigurations)
      }
    },

    updateCameraTemp(camera: CameraTemp) {
      const userData = camera.cameraData.userData
      if (userData !== undefined) {
        const cameraIndex = this.data.camera.cameraList.findIndex(
          (item) => item.cameraData.userData === userData
        )
        if (cameraIndex !== -1) {
          this.data.camera.cameraList[cameraIndex] = camera
        }
      }
    },

    cameraTempExists(userData: string): boolean {
      return this.data.camera.cameraList.some((c) => c.cameraData.userData === userData)
    },

    setStep(step: OnboardingStepEnum) {
      this.currentStep = step
    },

    setCamerasList(cameras) {
      this.data.camera.cameraList = cameras
    },

    setStaticIp(ip: string) {
      this.data.camera.staticIp = ip
    },

    blockTempCameras(bridgeId: string, locked: boolean) {
      this.data.camera.cameraList.forEach((c) => {
        if (c.cameraData.bridgeId && c.cameraData.bridgeId === bridgeId) {
          c.locked = locked
        }
      })
    },

    setBridgeSetupState(state: boolean = true, id?: string) {
      if (this.data.isBridgeSetupFinished !== state || (id && this.data.bridgeId !== id)) {
        if (id && !state) {
          this.data.isBridgeSetupFinished = false
          this.changeSelectedBridge(id)
        } else if (state) {
          this.data.isBridgeSetupFinished = true
          this.changeSelectedBridge('')
        }
      }
    },

    changeSelectedBridge(id: string) {
      if (this.data.bridgeId !== id) {
        this.data.bridgeId = id
      }
    }
  }
})
