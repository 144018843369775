import { PlayerCore } from '@/player/lib/player/player-core'
import { PlayerApis } from '@/player/lib/player/player-apis'
import type { PlayerParams } from '@/player/interfaces'
import { useServices } from '@/lib/services'
import type { CameraStatusData } from '@/lib/api'
import { computed, ref, watch } from 'vue'
import type { Camera } from '@/lib/api'
import type { CameraDetailedStatus } from '@/player/interfaces'

const service = useServices()

function makeRandomString(length: number): string {
  let result: string = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export class PlayerRepository {
  protected static altKey = false
  protected static shiftKey = false
  protected static dKey = false

  static cameraStatus = ref<CameraStatusData[]>([])
  protected static cameraStatusInterval: ReturnType<typeof setInterval>
  static checkStatus = false

  static async getStatus() {
    try {
      if (
        service.camera.getHeader('Authorization') &&
        (localStorage.getItem('workspaceId') || sessionStorage.getItem('workspaceId'))
      ) {
        PlayerRepository.cameraStatus.value = await service.camera.statusAllCamera()
        const players = getPlayerRepository().players.values()
        for (const player of players) {
          const status = PlayerRepository.cameraStatus.value.find(
            (s) => s.cameraId === player.cameraId
          )
          if (status) {
            player.detailedStatus.value = status.status
          }
        }
      }
    } catch (e) {
      console.warn(e)
    }
  }

  static initStatusWatch() {
    if (!PlayerRepository.checkStatus) {
      PlayerRepository.getStatus()
      clearInterval(PlayerRepository.cameraStatusInterval)
      PlayerRepository.cameraStatusInterval = setInterval(PlayerRepository.getStatus, 10_000)
      PlayerRepository.checkStatus = true
    }
  }

  static stopStatusWatch() {
    if (PlayerRepository.checkStatus) {
      clearInterval(PlayerRepository.cameraStatusInterval)
      PlayerRepository.checkStatus = false
    }
  }

  static updateStatus(cameraId: string, status: CameraDetailedStatus) {
    setTimeout(() => {
      // to not block the event loop
      const players = getPlayerRepository().players.values()
      for (const player of players) {
        if (cameraId === player.cameraId) {
          player.detailedStatus.value = status
        }
      }
    })
  }

  static refreshMask(cameraId: string) {
    setTimeout(() => {
      // to not block the event loop
      const players = getPlayerRepository().players.values()
      for (const player of players) {
        if (cameraId === player.cameraId) {
          player.apis.information()
        }
      }
    })
  }

  static refreshBookmarks(cameraId: string) {
    setTimeout(() => {
      // to not block the event loop
      const players = getPlayerRepository().players.values()
      for (const player of players) {
        if (cameraId === player.cameraId) {
          player.apis.bookmarkList()
        }
      }
    })
  }

  static refreshBookmarksForAllPlayers() {
    setTimeout(() => {
      // to not block the event loop
      const players = getPlayerRepository().players.values()
      for (const player of players) {
        player.apis.bookmarkList()
      }
    })
  }

  static handleKeyDown(e: KeyboardEvent) {
    const key = e.key.toUpperCase()
    if (key === 'ALT') {
      PlayerRepository.altKey = true
    }
    if (key === 'SHIFT') {
      PlayerRepository.shiftKey = true
    }
    if (key === 'D') {
      PlayerRepository.dKey = true
    }
    if (PlayerRepository.activePlayer && !PlayerRepository.activePlayer.closed) {
      PlayerRepository.activePlayer.helpers.keyboardShortcuts.onKeyPress(key)
    }
  }

  static handleKeyUp(e: KeyboardEvent) {
    if (e.key === 'Alt') {
      PlayerRepository.altKey = false
    }
    if (e.key === 'Shift') {
      PlayerRepository.shiftKey = false
    }
    if (e.key === 'd' || e.key === 'D') {
      PlayerRepository.dKey = false
    }
  }

  protected static isDevMode() {
    return PlayerRepository.altKey && PlayerRepository.shiftKey && PlayerRepository.dKey
  }

  static setupDevMode(core: string) {
    if (PlayerRepository.isDevMode()) {
      const player = getPlayer(core)
      player.worker.enableLogger()
      const popUp = window.open('', 'DevTools', 'width=400,height=100')
      popUp?.addEventListener('close', () => {
        player.worker.disableLogger()
      })
      if (popUp) {
        popUp.document.write('<p id="data">Loading...</p>')
        const interval = setInterval(async () => {
          if (popUp) {
            if (popUp.closed || player.closed) {
              clearInterval(interval)
            } else {
              const data = await player.worker.getState()
              const el = popUp.document.getElementById('data')
              if (el) {
                el.innerHTML = JSON.stringify(data, undefined, 2).replace(/\n/g, '<br>')
              }
            }
          } else {
            clearInterval(interval)
          }
        }, 1000)
      }
      return true
    } else return false
  }

  players: Map<string, PlayerCore> = new Map()

  static activeAudioPlayer?: PlayerCore
  static activePlayer?: PlayerCore

  static app = new PlayerRepository()

  public onNewPlayers: undefined | ((player: PlayerCore) => unknown)

  async makePlayerCore(cameraId: number | string, params?: PlayerParams) {
    params = params || {}
    const object = await PlayerApis.information(cameraId)
    const id = makeRandomString(6)
    const core = new PlayerCore(object, id)
    const bootPromise = core.initialize(params)
    this.players.set(id, core)
    bootPromise.then(() => {
      if (this.onNewPlayers) {
        this.onNewPlayers(core)
      }
    })
    return {
      instance: core,
      bootPromise
    }
  }

  get(id: string): PlayerCore {
    const core = this.players.get(id)
    if (!core) throw new Error('player not found!')
    return core
  }

  remove(id: string) {
    const player = this.get(id)
    player.destroy()
    this.players.delete(id)
  }

  updateInformation(camera: Camera) {
    this.players.forEach((player) => {
      if (player.information.id === camera.id) {
        player.apis.information(camera)
      }
    })
  }
}

const repository = new PlayerRepository()

export function getPlayerRepository() {
  return repository
}

export function makePlayer(cameraId: string | number, params?: PlayerParams) {
  return repository.makePlayerCore(cameraId, params)
}

export function getPlayer(id: string) {
  return repository.get(id)
}

export function removePlayer(id: string) {
  return repository.remove(id)
}

window.addEventListener('keydown', PlayerRepository.handleKeyDown)
window.addEventListener('keyup', PlayerRepository.handleKeyUp)

PlayerRepository.initStatusWatch()
