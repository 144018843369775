import type { CameraHardwareInformation } from '@/lib/api'
import { CameraCore } from '@/modules/Camera/CameraCore'
import { SyncAbleObject } from '@/modules/Camera/SyncAbleObject'
import type { CameraCoreComposite } from '@/modules/Camera/interface'
import type { Camera as CameraObject } from '@/lib/api'

export class CameraHardware
  extends SyncAbleObject<CameraHardwareInformation>
  implements CameraCoreComposite
{
  public readonly id: string

  constructor(
    public readonly core: CameraCore,
    cameraObject: CameraObject
  ) {
    super(CameraHardware.parseCameraObject(cameraObject))
    this.id = this.core.id
  }

  protected static parseCameraObject(camera: CameraObject): CameraHardwareInformation {
    return {
      features: camera.hardwareInformation.features,
      model: camera.hardwareInformation.model,
      manufacturer: camera.hardwareInformation.manufacturer,
      hardwareID: camera.hardwareInformation.hardwareID,
      serialNo: camera.hardwareInformation.serialNo,
      firmware: camera.hardwareInformation.firmware,
      ip: camera.hardwareInformation.ip,
      MAC: camera.hardwareInformation.MAC,
      comments: camera.hardwareInformation.comments
    }
  }

  public initialCameraData(cameraObject: CameraObject) {
    Object.assign(this.data, CameraHardware.parseCameraObject(cameraObject))
  }
}
