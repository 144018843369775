import { useLayoutStoreWrapper } from '@/modules/Monitoring/helpers/layoutStoreWrapper'
import { useTabStore } from '@/stores/tab/useTabStore'
import { useRoute, useRouter } from 'vue-router'
import { useCameraStoreWrapper } from '@/modules/Monitoring/helpers/cameraStoreWrapper'
import { type LayoutBoxConfig } from '@/player/interfaces'
import { CustomSettingsType, WorkspaceHelper, type TabsResponse } from '@/lib/api'
import type { CameraBaseObject } from '@/modules/Camera/interface'
import type { CameraCore } from '@/modules/Camera/CameraCore'
import { ref } from 'vue'

let staticNewTabPromise: undefined | Promise<unknown> = undefined
export function useMonitoringTabsWrapper() {
  const maxCameraOnMonitoring = ref(10)
  const layoutManager = useLayoutStoreWrapper()
  const cameraStore = useCameraStoreWrapper()
  const tabStore = useTabStore()
  const router = useRouter()
  const route = useRoute()
  function createMonitoringTab_dep() {
    const id = layoutManager.createId()
    staticNewTabPromise = layoutManager
      .create(id, { viewSetup: [] })
      .then(() => addBasicCameras(id))
      .finally((staticNewTabPromise = undefined))
    return {
      name: 'streaming',
      params: {
        tabId: id
      }
    }
  }

  async function createMonitoringTabAsync(cameras?: Array<CameraBaseObject | CameraCore>) {
    const id = layoutManager.createId()
    const hasCameras = !!cameras

    staticNewTabPromise = layoutManager
      .create(id, { viewSetup: cameras ?? [] })
      .then(() => {
        if (!hasCameras) {
          return addBasicCameras(id)
        }
      })
      .finally(() => {
        staticNewTabPromise = undefined
      })

    await staticNewTabPromise

    const name = getNewName()

    staticNewTabPromise = tabStore
      .createTab(
        {
          name: name,
          route: 'streaming',
          settings: {
            type: CustomSettingsType.userWorkspace,
            key: 'streaming',
            value: {
              tabId: id
            }
          }
        },
        false
      )
      .finally(() => {
        staticNewTabPromise = undefined
      })

    await staticNewTabPromise

    return {
      name,
      params: {
        tabId: id
      }
    }
  }

  async function addBasicCameras(id: string) {
    await cameraStore.loadCameras()
    const cameras = [...cameraStore.camerasBasic.value]
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      .slice(0, maxCameraOnMonitoring.value)
      .map((c) => ({ id: c.id }))
    return layoutManager.update(id, { viewSetup: cameras })
  }

  function goOrCreateMonitoring() {
    const monitoringTab = tabStore.tabs.find((t) => t.route === 'streaming')
    if (monitoringTab) {
      tabStore.changeActiveTab(monitoringTab)
    } else {
      return router.push(createMonitoringTab_dep())
    }
  }

  function goToListView() {
    return router.push({
      name: 'streamingListView'
    })
  }

  async function duplicateConfigView() {
    let viewSetup: LayoutBoxConfig[] = []
    if (route.name === 'streaming' && route.params.tabId) {
      viewSetup = (await layoutManager.load(route.params.tabId.toString())).viewSetup
    } else {
      await cameraStore.loadCameras()
      viewSetup = cameraStore.camerasBasic.value.map((c) => ({ id: c.id }))
    }
    const tabId = layoutManager.createId()
    await layoutManager.create(tabId, { viewSetup })
    staticNewTabPromise = layoutManager
      .create(tabId, { viewSetup })
      .finally((staticNewTabPromise = undefined))
    return tabId
  }

  async function createSyncPlayback(date: Date) {
    const tabId = await duplicateConfigView()
    await router.push({
      name: 'streamingPlayback',
      params: {
        tabId
      },
      query: {
        date: date.getTime()
      }
    })
  }

  async function createHeatMap(date: Date) {
    const tabId = await duplicateConfigView()
    await router.push({
      name: 'streamingHeatmap',
      params: {
        tabId
      },
      query: {
        date: date.getTime()
      }
    })
  }

  async function duplicateMonitoring(id: string) {
    const configs = await layoutManager.load(id)
    const tabId = await duplicateConfigView()
    await layoutManager.create(tabId, configs)
    staticNewTabPromise = layoutManager
      .create(tabId, configs)
      .finally((staticNewTabPromise = undefined))
    await router.push({
      name: 'streaming',
      params: {
        tabId
      }
    })
  }

  function splitArray<T>(array: T[], chunkSize: number): T[][] {
    if (chunkSize <= 0) {
      throw new Error('Chunk size must be greater than 0')
    }

    const result: T[][] = []
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize))
    }

    return result
  }

  function getNewName(baseName: string = 'Monitoring', route: string = 'streaming'): string {
    const tabNames = tabStore.tabs.filter((t) => t.route === route).map((t) => t.name)

    let count = tabNames.length

    if (tabNames.length === 0) {
      return baseName
    }

    let newName = `${baseName} ${WorkspaceHelper.integerToRoman(count)}`

    while (tabNames.includes(newName)) {
      count++
      newName = `${baseName} ${WorkspaceHelper.integerToRoman(count)}`
    }

    return newName
  }

  async function createMonitoringForAllCameras(): Promise<Array<TabsResponse<any>>> {
    await cameraStore.loadCameras()
    const camerasChunks = splitArray(cameraStore.camerasBasic.value, maxCameraOnMonitoring.value)

    for (const cameras of camerasChunks) {
      await createMonitoringTabAsync(cameras)
    }
    return tabStore.tabs.filter((t) => t.route === 'streaming') ?? []
  }

  function getNewTabPromise() {
    return staticNewTabPromise
  }

  async function _addCameraToTab(id: string, tabId: string) {
    if (tabId) {
      const settings = await layoutManager.load(tabId)
      if (settings.viewSetup.length < maxCameraOnMonitoring.value) {
        const max = (settings.layout.columns?.length ?? 0) * (settings.layout.rows?.length ?? 0)
        if (settings.viewSetup.length < max) {
          settings.viewSetup.push({
            id
          })
          await layoutManager.update(tabId, settings)
        }
      }
    }
  }

  async function addNewCameraToTabs(id: string) {
    const requests = tabStore.arrayTabs.map((tab) => {
      if (tab.route === 'streaming' && tab.settings.value.tabId) {
        return _addCameraToTab(id, tab.settings.value.tabId)
      }
    })
    await Promise.all(requests.filter((r) => r))
  }

  return {
    maxCameraOnMonitoring,
    createMonitoringTab: createMonitoringTab_dep,
    createMonitoringTabAsync,
    getNewTabName: getNewName,
    goOrCreateMonitoring,
    goToListView,
    createSyncPlayback,
    createHeatMap,
    duplicateMonitoring,
    createMonitoringForAllCameras,
    getNewTabPromise,
    addNewCameraToTabs
  }
}
