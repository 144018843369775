import { defineStore, storeToRefs } from 'pinia'
import { useEventBus } from '@/utils/event-bus/EventBus'
import { useTabStore } from '@/stores/tab/useTabStore'
import type { TabsResponse } from '@/lib/api'
import { CustomSettingsType } from '@/lib/api'
import type { OnboardingState } from '@/modules/Onboarding/onboarding.interface'
import { useOnboardingStore } from '@/modules/Onboarding/store/useOnboardingStore'
import { useMonitoringStore, type MonitoringState } from '@/stores/tab/monitoring'
import { GridListEnum } from '@/components/monitoring/interfaces'
import { OnboardingStepEnum } from '@/modules/Onboarding/onboarding.interface'

interface impexState {
  loading: boolean
  profile: impexProfile
  workspace: impexWorkspace
  onboarding: OnboardingState
  monitoring: MonitoringState
}

interface impexProfile {
  fullName: string
  timezone: string
  email: string
  phone: string
}

interface impexWorkspace {
  name: string
  description: string
}

export const useImpexStore = defineStore('impexStore', {
  state: (): impexState => ({
    loading: false,
    profile: {
      fullName: '',
      timezone: '',
      email: '',
      phone: ''
    },
    workspace: {
      name: '',
      description: ''
    },
    onboarding: {
      steps: [],
      nextStep: OnboardingStepEnum.CAMERAS,
      currentStep: OnboardingStepEnum.WORKSPACE,
      data: {
        camera: {
          currentStep: 1,
          cameraList: [],
          selections: [],
          process: [0, 0, 0, 0],
          staticIp: '',
          verifyConfigureIp: false
        },
        payment: {
          fullName: '',
          zipCode: ''
        },
        cameraUsers: [],
        isBridgeSetupFinished: false,
        bridgeId: ''
      }
    },
    monitoring: {
      viewMode: GridListEnum.box
    }
  }),

  actions: {
    async updateTabState(routerName: string) {
      try {
        this.loading = true
        const { tabs } = storeToRefs(useTabStore())
        const tab = tabs.value.find((item) => item.route === routerName)
        if (tab)
          switch (tab.route) {
            case 'profileSettings':
              await this.updateProfileTabState(tab)
              break
            case 'workspaceSettings':
              await this.updateWorkspaceTabState(tab)
              break
            case 'billingSettings':
              // todo add this
              break
            case 'permissionSettings':
              await this.updatePermissionTabState(tab)
              break
            case 'onboarding':
              await this.updateOnboardingTabState(tab)
              break
            case 'cameraConnect':
              await this.updateCameraConnectTabState(tab)
              break
            case 'monitoring':
              await this.updateMonitoringTabState(tab)
              break
          }
      } finally {
        this.loading = false
      }
    },

    async updateMonitoringTabState(tab: TabsResponse) {
      const eventBus = useEventBus()
      eventBus.emit('export:monitoring')
      const monitoringState = useMonitoringStore().$state
      await useTabStore().UpdateTab(String(tab?.id), {
        route: tab.route,
        settings: {
          key: 'monitoring',
          value: monitoringState,
          type: CustomSettingsType.userWorkspace
        }
      })
    },

    async updateProfileTabState(tab: TabsResponse) {
      const eventBus = useEventBus()
      eventBus.emit('export:profileSettings')
      await useTabStore().UpdateTab(String(tab?.id), {
        route: tab.route,
        settings: {
          key: 'profileSettings',
          value: this.profile,
          type: CustomSettingsType.userWorkspace
        }
      })
    },

    async updateWorkspaceTabState(tab: TabsResponse) {
      const eventBus = useEventBus()
      eventBus.emit('export:workspaceSettings')
      await useTabStore().UpdateTab(String(tab?.id), {
        route: tab.route,
        settings: {
          key: 'workspaceSettings',
          value: this.workspace,
          type: CustomSettingsType.userWorkspace
        }
      })
    },

    async updatePermissionTabState(tab: TabsResponse) {
      const eventBus = useEventBus()
      eventBus.emit('export:permissionSettings')
      await useTabStore().UpdateTab(String(tab?.id), {
        route: tab.route,
        settings: null
      })
    },

    async updateOnboardingTabState(tab: TabsResponse) {
      const eventBus = useEventBus()
      const onboardingStore = useOnboardingStore()
      this.onboarding = onboardingStore.$state
      eventBus.emit('export:onboarding')
      await useTabStore().UpdateTab(String(tab?.id), {
        route: tab.route,
        settings: {
          key: 'onboarding',
          value: this.onboarding,
          type: CustomSettingsType.userWorkspace
        }
      })
    },

    async updateCameraConnectTabState(tab: TabsResponse) {
      const eventBus = useEventBus()
      const onboardingStore = useOnboardingStore()
      this.onboarding = onboardingStore.$state
      eventBus.emit('export:onboarding')
      await useTabStore().UpdateTab(String(tab?.id), {
        route: tab.route,
        settings: {
          key: 'cameraConnect',
          value: this.onboarding,
          type: CustomSettingsType.userWorkspace
        }
      })
    },

    setTabState(tabs: TabsResponse[]) {
      const onboardingStore = useOnboardingStore()
      tabs.forEach((tab) => {
        switch (tab.route) {
          case 'profileSettings':
            this.profile = tab.settings
              ? tab.settings.value
              : {
                  fullName: '',
                  timezone: '',
                  email: '',
                  phone: ''
                }
            break
          case 'securitySettings':
            break
          case 'monitoring':
            this.monitoring = tab.settings
              ? tab.settings.value
              : {
                  viewMode: GridListEnum.box
                }
            useMonitoringStore().$state = this.monitoring
            break
          case 'workspaceSettings':
            this.workspace = tab.settings
              ? tab.settings.value
              : {
                  name: '',
                  description: ''
                }
            break
          case 'onboarding':
            this.onboarding = tab.settings
              ? {
                  ...tab.settings.value,
                  steps: useOnboardingStore().initializeSteps(
                    tab.settings.value.steps,
                    'onboarding'
                  )
                }
              : {
                  steps: [],
                  nextStep: 'cameras',
                  currentStep: 'workspace',
                  data: {
                    camera: {
                      currentStep: 1,
                      cameraList: [],
                      selections: [],
                      process: [0, 0, 0, 0, 0],
                      staticIp: '',
                      verifyConfigureIp: false
                    },
                    payment: {
                      fullName: '',
                      zipCode: ''
                    },
                    cameraUsers: []
                  }
                }
            onboardingStore.$state = this.onboarding
            break
          case 'cameraConnect':
            this.onboarding = tab.settings
              ? {
                  ...tab.settings.value,
                  steps: useOnboardingStore().initializeSteps(
                    tab.settings.value.steps,
                    'cameraConnect'
                  )
                }
              : {
                  steps: [],
                  nextStep: 'cameras',
                  currentStep: 'workspace',
                  data: {
                    camera: {
                      currentStep: 1,
                      cameraList: [],
                      selections: [],
                      process: 0,
                      staticIp: '',
                      verifyConfigureIp: false
                    },
                    payment: {
                      fullName: '',
                      zipCode: ''
                    },
                    cameraUsers: []
                  }
                }
            onboardingStore.$state = this.onboarding
            break
          case 'permissionSettings':
            break
        }
      })
    }
  }
})
