import { useServices } from '@/lib/services'

export type EventCallback = (...args: any[]) => void

export class EventManager {
  protected listeners: Map<string, Array<EventCallback | false>> = new Map()
  protected throttler: Map<string, ReturnType<typeof setTimeout>> = new Map()

  on(ev: string, callback: EventCallback): number {
    const evMapTmp = this.listeners.get(ev)
    if (!evMapTmp) {
      this.listeners.set(ev, [])
    }
    const evMap = this.listeners.get(ev)
    if (evMap) {
      return evMap.push(callback) - 1
    } else throw new Error()
  }

  off(ev: string, id: number): void {
    const evMap = this.listeners.get(ev)
    if (evMap) {
      evMap[id] = false
    }
  }

  emit(ev: string, ...parameters: any[]) {
    const evMap = this.listeners.get(ev)
    if (evMap) {
      for (const callback of evMap) {
        if (callback) {
          callback(...parameters)
        }
      }
    }
  }
}

export enum SessionSocketStatus {
  connecting,
  connected,
  revoked,
  takeover,
  error
}

export enum CloseReasons {
  logout = 'logout',
  revoked = 'InvalidSessionData',
  takeover = 'ActivatingAnotherSession'
}

export enum SocketVersion {
  legacy = 'legacy',
  v1 = 'v1'
}

const ON_SUCCESS_MSG = 'SuccessfulConnection'
const ON_PING = 'SocketPing'
const ON_PONG = 'SocketPong'
const SPLITTER = '@'
const RETRY_DELAY = 100

// session takeover, debug retry, session  revoke, ready
export class SessionClient extends EventManager {
  protected socket!: WebSocket
  protected retryCount = 0
  protected _status = SessionSocketStatus.connecting
  protected onReadyHandel!: () => void
  protected onErrorHandel!: () => void // todo
  protected closed = false

  get status() {
    return this._status
  }

  constructor(
    protected baseURL: string,
    protected version: SocketVersion = SocketVersion.legacy
  ) {
    super()
  }

  start(): Promise<void> {
    this.closed = false
    return new Promise((resolve, reject) => {
      this.onReadyHandel = resolve
      this.onErrorHandel = reject
      this.connect()
    })
  }

  stop() {
    this.closed = true
    if (this.isSocketOpen() || this.isSocketConnecting()) {
      this.socket.close(1000, CloseReasons.logout)
    }
  }

  protected triggerReady() {
    this.emit('ready')
    if (this.onReadyHandel) {
      this.onReadyHandel()
    }
  }

  protected triggerRevoked() {
    this._status = SessionSocketStatus.revoked
    this.stop()
    this.emit('revoked')
  }

  protected triggerError(e?: unknown) {
    this._status = SessionSocketStatus.error
    this.retry()
    this.listeners.clear()
  }

  protected triggerTakeover(reason: string) {
    console.log('triggerTakeover -------- ')
    console.log(reason)
    const [type, ipCode, ip, browser, os] = reason.split(SPLITTER)
    if (type === CloseReasons.takeover) {
      this._status = SessionSocketStatus.takeover
      this.stop()
      this.emit('takeover', { ipCode, ip, browser, os })
    } else {
      throw new Error()
    }
  }

  protected isSocketOpen() {
    return this.socket?.readyState === WebSocket.OPEN
  }

  protected isSocketConnecting() {
    return this.socket?.readyState === WebSocket.CONNECTING
  }

  protected onOpen(ev: Event) {
    this.emit('open', ev)
  }

  protected onError(ev: Event) {
    this.emit('error', ev)
  }

  protected onClose(ev: CloseEvent) {
    console.log('close Socket')
    console.log(ev)
    if (ev.code !== 1000) {
      return this.triggerError(ev)
    } else {
      const { reason } = ev
      if (reason === CloseReasons.revoked) {
        this.triggerRevoked()
      } else if (reason === CloseReasons.logout) {
        if (!this.closed) {
          this.triggerError(ev)
        }
      } else if (reason.startsWith(CloseReasons.takeover)) {
        this.triggerTakeover(reason)
      }
    }
  }

  protected onMessage(ev: MessageEvent) {
    if (typeof ev.data === 'string' && ev.data === ON_SUCCESS_MSG) {
      this._status = SessionSocketStatus.connected
      this.triggerReady()
    } else if (typeof ev.data === 'string' && ev.data === ON_PING) {
      this.socket.send(ON_PONG)
    } else if (typeof ev.data === 'string') {
      const messageType = ev.data

      if (this.throttler.has(messageType)) {
        clearTimeout(this.throttler.get(messageType))
      }

      this.throttler.set(
        messageType,
        setTimeout(() => {
          this.processMessage(ev.data)
        }, 1000)
      )
    }
  }

  protected processMessage(data: string) {
    if (data[0] === '{') {
      this.processJSON(data)
    } else {
      this.processString(data)
    }
  }
  protected processJSON(data: string) {
    const parsedData = JSON.parse(data)
    const messageType = parsedData.subject

    const callbacks = this.listeners.get(messageType)
    if (callbacks && !this.closed) {
      for (const callback of callbacks) {
        if (callback) callback(parsedData)
      }
    }
  }
  protected processString(data: string) {
    const messageType = data.split(':')[0]
    const messageContent = data.split(':').slice(1)

    const callbacks = this.listeners.get(messageType)
    if (callbacks) {
      for (const callback of callbacks) {
        if (callback) callback(messageContent)
      }
    }
  }

  protected cleanup(status = SessionSocketStatus.connecting) {
    if (this.isSocketOpen() || this.isSocketConnecting()) {
      this.socket.close()
    }
    this._status = status
    this.retryCount = 0
  }

  protected static sleep(time: number) {
    return new Promise((r) => setTimeout(r, time))
  }

  protected async retry() {
    if (this.status === SessionSocketStatus.error) {
      this.retryCount++
      this.emit('retry', this.retryCount)
      await SessionClient.sleep(RETRY_DELAY)
      await this.connect()
    }
  }

  protected async connect() {
    this.cleanup()
    const token = await this.getToken()
    this.socket = new WebSocket(`${this.baseURL}?version=${this.version}&token=${token}`)
    this.socket.onopen = this.onOpen.bind(this)
    this.socket.onerror = this.onError.bind(this)
    this.socket.onclose = this.onClose.bind(this)
    this.socket.onmessage = this.onMessage.bind(this)
  }

  protected async getToken() {
    const { token } = await useServices().userEventBus.getSocketToken()
    return token
  }
}
