const groupSettings = {
  titles: {
    aiEvents: 'AI & Video Intelligence Suite',
    other: 'Other',
    others: 'Other', // NOTICE: Backend work with "others" and front works with "other" so we need both keys
    payment: 'Billing & Invoicing',
    camera: 'Camera & System Health',
    security: 'Security'
  },
  switchTexts: {
    on: 'Always',
    off: 'Never'
  },
  FIREBASE: 'In-App Notifications',
  EMAIL: 'Email Notifications',
  SMS: 'SMS Alerts'
}

export default {
  messages: {
    emptyDropdown: 'Enter a free-key tag!',
    expired: 'Expired code.',
    otpError: 'Invalid code.',
    tooManySendSMSNoRemainSec: 'Too many requests, please wait for a few seconds and try again.',
    tooManySendSMS: 'Too many requests, please wait for {seconds} seconds.',
    enableMultiFactor:
      'First, you need to enable your \n' + 'Multi-Factor Authentication By Text Message.',
    enable: 'Enable',
    isRemoved: 'is removed.',
    verified: 'Verified',
    validCode: 'Please enter a valid code.',
    passUpdate: 'Updated!',
    dataNotFound: 'Nothing to show.',
    page: 'Page',
    of: 'of',
    all: 'All',
    checkEmail: 'Check your email now! A verification link has been sent to {email}',
    portError: 'Port must be integer between 1-65535.',
    channelError: 'Secure port must be integer between 1-64.',
    securePortError: 'Secure port must be integer between 0-65535.',
    extraPortError: 'Extra port must be integer between 0-65535.',
    urlError: 'Invalid format.',
    incurrectName: 'Please enter name in 1 to 74 character',
    timezone: 'Please select one of the items in list',
    successfullUpdate: 'Updated successfully',
    removed: 'Removed successfully',
    saved: 'Saved!',
    noMoreData: 'Nothing to show'
  },
  labels: {
    addCard: 'Add Card',
    cardName: 'Name on Card',
    CommitmentFree: 'Commitment-free zone: bow out anytime without any fees.',
    countryCode: 'Country Code',
    phoneNumber: 'Phone Number',
    password: 'Password',
    fullName: 'Full name',
    zipCode: 'Zip Code',
    email: 'Email',
    numberCameraLabel: 'How many cameras do you own?',
    numberCamera: 'Number Camera',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    verificationCode: 'Enter the verification code',
    by: 'by',
    all: 'All',
    // Active
    //
    // Inactive
    //
    // Deleted
    active: 'Active',
    inactive: 'Inactive',
    deleted: 'Deleted',
    online: 'Online',
    offline: 'Offline',
    other: 'other',
    hey: 'Hey {name},',
    invite: 'Invite',
    free: '1 Month Free',
    until: 'Until {date}',
    suspend: 'Suspend'
  },
  passWordValidate: {
    atLeast10: 'MUST contain at least 10 characters',
    oneUpper: 'MUST contain at least one uppercase letter',
    oneNumber: 'MUST contain at least one number',
    oneSpecial: 'MUST contain at least one special character',
    strong: 'MUST contain Strong enough'
  },
  passWordStrength: {
    veryWeak: 'too weak',
    weak: 'weak',
    medium: 'medium',
    strong: 'strong',
    veryStrong: 'too strong'
  },
  logo: {
    altText: 'Arcadian',
    toolTip: 'Arcadian'
  },
  actions: {
    gmail: 'Gmail',
    facebook: 'Facebook',
    linkedin: 'Linkedin',
    sendAgain: 'Send Again',
    logIn: 'Log In',
    signup: 'Sign Up',
    ok: 'Ok',
    cancel: 'Cancel',
    confirm: 'Confirm',
    edit: 'Edit',
    save: 'Save',
    discard: 'Discard',
    back: 'Back',
    verify: 'Verify',
    verifyNow: 'Verify Now',
    unverified: 'Unverified',
    actions: 'Load More',
    addManuallyCamera: 'Add Manually Camera',
    cancelPairing: 'Cancel Pairing',
    BetaFeedback: 'Beta Feedback',
    submitTicket: 'Submit Ticket',
    upload: 'Upload',
    undo: '/Undo',
    on: 'ON',
    off: 'OFF',
    help: 'Help',
    loadMore: 'Load More',
    tryAgain: 'Try Again',
    goToMonitoring: 'Go To Monitoring',
    delete: 'Delete',
    backTOMonitoring: 'Back to Monitoring',
    contactUs: 'Contact Us',
    checkoutAIStore: 'Checkout AI Store',
    pauseUpcomingBills: 'Pause Upcoming Bills',
    RemoveChat: 'Remove Chat',
    createNewChat: 'New Chat'
  },
  errorMessage: {
    error: '404: PARDON THE INTERRUPTION',
    InvalidDriver: 'hey, you sure you have the right brand? That looks like an RTSP link.',
    removeWorkspace: 'This workspace has been removed',
    updateSubscription: 'Well done! Your new plan has been saved successfully',
    successfulRemoveSeat:
      'Enjoy your savings! Plan downgrade is confirmed. Future invoices are available in Workspace Billing.',
    RemoveLastSeat:
      "{'Welcome to a seamless plug-and-play experience! Your exclusive virtual camera seat is permanently yours, a testament to our commitment. Questions? Reach out directly at sales@arcadian.ai.'}",
    zipCodeWrong: 'Invalid.',
    waitForDoActions: "We're saving your changes. ",
    SubscriptionProblem:
      "hey, we've noticed a subscription issue. Please add a valid payment method. If the problem persists, contact us at +1 (800) 288-9192 or sales{'@'}arcadian.ai. We're here to help!",
    failTrial: "Oh dear! You've hit a space timeout. Tap to pay and dive back into play.",
    refresh: 'Oops! Try refreshing your screen or reach out to us.',
    closeLastTab: 'Unable to close latest tab.',
    canNotRemoveWorkspace:
      'Planning to leave? Please get in touch with our support team to initiate your data deletion process.',
    workspaceSecurity: 'Workspace Security',
    newDeviceConnected: 'New Device Connected',
    changeDevice: 'Switch to {data}',
    stayDevice: 'Stay on my {data}',
    help: 'Help',
    BackToMonitoring: 'Back to Monitoring',
    notAvailable: 'PAGE NOT AVAILABLE',
    invalid: 'You either don’t have permissions to view or the link is invalid.',
    goBack: 'Go back',
    passwordWrong: "Oops! Password doesn't match our records.",
    AddNewPaymentMethods: 'Saved!',
    createSubscription: 'All Set!',
    noBillingAddress:
      'Hello!\n To calculate your totals, please ensure you have a valid payment method on file. Alternatively, feel free to use our pricing calculator available on our website.',
    oop: 'Oop! Something went wrong.',
    matchesName: 'Enter your first name, followed by your last name.',
    updateNumberDuplicate: 'Number already taken. Select a new one or contact us.',
    invalidCode: 'Enter a valid code.',
    interrupt: {
      title: '404: CONNECTION HICCUP DETECTED',
      refresh1: "Something's amiss! ",
      refresh2: 'Ensure your internet is on and tap refresh.',
      back: 'Back to Monitoring'
    },
    stripe: {
      callSales:
        "Try again. Looks like there’s an issue with your payment. For help, email sales{'@'}arcadian.ai",
      card: {
        card_declined: 'The card was declined for an unknown reason.',
        generic_decline:
          'The card was declined for an unknown reason or Stripe Radar blocked the payment.',
        insufficient_funds: 'The card has insufficient funds to complete the purchase.',
        lost_card: 'The payment was declined because the card is reported lost.',
        stolen_card: 'The payment was declined because the card is reported stolen.',
        card_velocity_exceeded:
          'You have exceeded the balance, credit limit, or transaction amount limit available on your card.',
        expired_card: 'The card has expired.',
        incorrect_cvc: 'The CVC number is incorrect.',
        processing_error: 'An error occurred while processing the card.',
        incorrect_number: 'The card number is incorrect.'
      }
    },
    fillRequiredFields: 'Please fill the required fields!!!',
    timeRangeBadOrder: '* Stay within a 24-hour timeframe.',
    timeRangeEqual: '* Please select a valid range of times.'
  },
  navigation: {
    newSpace: 'New Space',
    monitoring: 'Monitoring',
    Clips: 'Clips',
    People: 'People',
    invite: 'Invite',
    workspace: 'Workspace',
    Billing: 'Billing & Invoicing',
    spaceOverview: 'Space Overview',
    codeRedLock: 'Code Red Lock',
    Connect: ' Connect',
    dayFree: ' 1 Month Free',
    freeTrial: '1 Month Free',
    account: ' Account',
    security: ' Security',
    myprofile: ' My Profile',
    theme: 'Theme',
    LogOut: ' Log Out',
    shortcuts: 'Shortcuts'
  },
  tabMenu: {
    rename: 'Rename',
    close: 'Close',
    share: 'Share',
    monitoring: 'Monitoring',
    streamingListView: 'Devices Directory',
    profileSettings: 'My Profile',
    notificationsSettings: 'Settings',
    permissionSettings: 'People',
    permissions: 'Permissions',
    people: 'People',
    myPlan: 'My Plan',
    roles: 'Roles',
    securitySettings: 'Security',
    billingSettings: 'Billing',
    tutorial: 'How-To Videos',
    clips: 'Clips',
    AiHeatMap: 'AI Heatmaps',
    workspaceSettings: 'Workspace Overview',
    workspaceHours: 'Workspace Hours',
    businessHours: 'Business Hours',
    alertsHours: 'Alerts Hours',
    recents: 'Recents',
    quickLinks: 'Quick Links',
    newSpace: 'New Space',
    newRole: 'New Role',
    result: 'Results',
    connect: 'Connect',
    invite: 'Invite',
    invoice: 'Invoices',
    cameraConnect: 'Connect Cameras',
    onboarding: 'Onboarding',
    bridgeOnboarding: 'Onboarding',
    paymentMethod: 'Payment Methods',
    passwordRecovery: 'Password Recovery',
    passwordUpdate: 'Password Update',
    download: 'Download',
    snapshots: 'Snapshots',
    exports: 'Exports',
    activityHotspots: 'Activity Hotspots',
    liveStreaming: 'Live Streaming',
    allDevices: 'All Devices',
    listView: 'List View',
    RewindDate: 'Rewind to Specific Date',
    billing: 'Billing',
    upcomingPayments: 'Upcoming Payments',
    subscription: 'Subscription',
    upgradeDowngrade: 'Upgrade/Downgrade',
    loggedDevices: 'Logged in Devices',
    multiFactorAuthentication: 'Multi-factor Authentication',
    resetPassword: 'Reset Password',
    newPassword: 'New Password',
    emailVerification: 'Email Verification',
    PhoneVerification: 'Phone No. Verification',
    newPhoneNo: 'New Phone No.',
    newEmail: 'New Email',
    myTimezone: 'My Timezone',
    themes: 'Themes',
    playback: 'Rewind to Date',
    logs: 'Logs',
    activityLogs: 'Activity Logs',
    userLogs: 'User Logs',
    auditTrails: 'Audit Trails',
    shortcuts: 'Shortcuts',

    alertsSettings: 'Alerts Settings',
    manageNotifications: 'Manage Notifications',
    turnOffNotifications: 'Turn Off Notifications',
    scheduleNotifications: 'Schedule Notifications',
    inbox: 'Settings'
  },
  welcomeBeta: 'Welcome to Beta!',
  advanceHorizontal: 'Advanced feature sets on the horizon.',
  betaFeedBack: 'Beta Feedback',
  deniedTag: "You can't add this value",
  ok: 'Ok',
  disbaled: 'Disabled',
  timeRangeBadOrder: '* Stay within a 24-hour timeframe.',
  twitterFollow: "Ranger's Evolution on X",
  twitterUpdate: 'X Updates',
  hello: 'Hello',
  loading: 'Loading Data ...',
  noData: 'Nothing to show.',
  pleaseWait: 'Please Wait...',
  qrCode: {
    enableScanning: 'Tap to enable scanning'
  },

  //shortcuts
  shortcuts: {
    title: 'Keyboard Shortcuts',
    list: {
      'alt-c': { action: 'Open camera profile', keys: 'ALT + C' },
      'alt-ctrl-e': { action: 'Export clips', keys: 'ALT + CTRL + E' },
      'alt-s': { action: 'Take snapshots', keys: 'ALT + S' },
      'ctrl-b': { action: 'Drop new bookmarks', keys: 'CTRL + B' },
      'ctrl-m': { action: 'Show/hide privacy masks', keys: 'CTRL + M' },
      'alt-+': { action: 'Zoom in video', keys: 'ALT + "+"' },
      'alt--': { action: 'Zoom out video', keys: 'ALT + "-"' },
      esc: { action: 'Exit any action on video streaming', keys: 'ESC' },
      space: { action: 'Pause/Unpause video', keys: 'Space' },
      'up-arrow': { action: 'Longer timeline intervals on video streaming', keys: '' },
      'down-arrow': { action: 'Shorter timeline intervals on video streaming', keys: '' },
      'right-arrow': { action: 'Go forward 15 seconds in historical footage', keys: '' },
      'left-arrow': { action: 'Go back 15 seconds in historical footage', keys: '' }
    }
  },

  //notifications
  notifications: {
    routeName: 'notifications',
    subTabTitle: 'Settings',
    myInbox: 'Settings',
    title: 'Notifications',
    notifications: 'Notifications',
    notification: 'Notification',
    clickhere: 'click here.',
    LandonSecurity: 'Show me',
    Clips: 'Show me',
    LogIn: 'Show me',
    UserDetails: 'Show me',
    PeopleLanding: 'Show me',
    RoleDetails: 'Show me',
    camera: 'Show me',
    WorkspaceBillingLanding: 'Show me',
    emailWillSend: 'All notifications will be sent to',
    new: 'New',
    muteInterval: 'Cooldown Notifications?',
    muteIntervalTooltips:
      "Notification cooldown adds a brief delay to confirm if a threat is real before sending an alert. If the event continues, you'll still be notified—perfect for low-bandwidth or unstable connections.",
    inAppNotificationHelpTooltips:
      "Stay connected and never miss a beat! Enable this to receive all your in-app, desktop and push notifications—it's the ultimate way to stay in the loop",
    securityNotificationsTooltip:
      'Unavoidable security alert categories for a seamless, reliable, and safe experience include notifications for device tampering and valuable object monitoring.',
    otherNotificationsTooltip:
      'Always stay in the know about your most important account activity! Get updates on logins, permission invites, clip downloads, and other must-have essentials for a worry-free experience.',
    groupSettings,
    dropdown: {
      /*
      Using this enum for translation
      enum ScheduleMode {
        afterHours,
        inHours,
        all
      }
      */
      labels: {
        2: 'All Day, Everyday',
        1: 'Business hours',
        0: 'After hours'
      },
      descriptions: {
        2: 'Ideal for environments demanding uninterrupted 24/7 monitoring and alerts, ensuring constant vigilance and security.',
        1: 'Designed for industries that necessitate proactive AI alerts during operational hours, facilitating critical activities such as monitoring safety zones.',
        0: 'Ideal for businesses that want to be notified of motion, intrusions, and theft after operating hours when no one is on-site.'
      },
      footerButton: 'My hours',
      tooltip: 'My preferred schedule\nfor receiving proactive\nalerts',

      focus: {
        all: {
          label: 'All-Inclusive',
          description:
            'Stay in the loop with notifications from both ArcadianAI’s Cloud AI and your camera’s hardware analytics. This option ensures you never miss a beat, combining the strengths of both technologies for complete security coverage.'
        },
        sw: {
          label: 'Cloud AI Focus',
          description:
            'Got outdated hardware flooding you with false alerts? This option’s for you! Let ArcadianAI’s state-of-the-art Cloud AI take over, delivering ultra-accurate insights so you can skip the noise and stay focused on what matters.'
        },
        hw: {
          label: 'Hardware Only',
          description:
            "Rely solely on your camera's built-in hardware analytics. Perfect for devices with robust, on-premise features already configured to your needs."
        }
      }
    },
    description: {
      title: 'My Inbox',
      text: "Everything you've snoozed, all in one place."
    },
    actions: {
      goToInbox: 'Settings',
      clear: 'Clear',
      verifyNewEmail: 'Verify New Email',
      clearAll: 'Clear All'
    },
    fastFilters: {
      all: 'All',
      ...groupSettings.titles
    },
    emptyDrawer: {
      dearUser: 'hey {fullname}',
      content:
        "Inbox Zero! You've cleared all your important notifications. In the meantime, learn about our business advocacy initiative.",
      bringFriendEarnCash: 'Bring a Friend, Earn Cash 💸'
    },
    slider: {
      title: 'spotted movement. Play now.',
      aiTooltip:
        'Meet Ranger—he’s your AI security guard. No sleep, no breaks. Reliable. Vigilant. Unstoppable.',
      aiDefaultText: 'Ranger is analyzing. . .',
      aiDefaultTextNotFound: 'Ranger found no results during analysis.',
      actions: {
        playNow: 'Play Now',
        openNewTab: 'Open Full Screen in New Tab',
        bookmark: 'Bookmark This Moment',
        export: 'Export Images',
        download: 'Download Video',
        link: "You'll soon be able to share this clip safely with a secure link. Stay tuned!"
      },
      bookmark: {
        request: {
          title: 'Created by Ranger'
        }
      }
    },

    message: {
      successfulBookmark: 'Saved! This moment has been bookmarked.',
      successFullExport: "Prepping! You'll get a ping soon to download.",
      failedExport: 'Unsuccessful attempt to save the moment',
      failedToLoadPlayer: 'Could not Load the Player',
      brokenMetaData: 'Metadata is missing or invalid.'
    },
    errorMessages: {
      exportClips: 'Export Clips',
      invalidTimestampBookmark: 'Invalid timestamps for bookmark.',
      invalidTimestampExport: 'Invalid timestamps for export.',
      privacyMask: "To download clips, you'll need to remove the privacy zone set for this camera",
      exportDiffTimeLimit:
        'Whoa, that’s a big file! Your plan’s got a 15-minute clip limit. Pick a shorter event to download!'
    },
    rangerOnUnhealthyCameras:
      'Hey human, I can’t see anything! Either your hardware’s playing hide-and-seek or your network took a coffee break—can you check?',
    ranger:
      'Hey, I’m Ranger—your AI security guard. I’m always online. I never take breaks, never nap, and never miss a thing. Always vigilant. Always on duty.'
  },

  //camera
  camera: {
    live: 'Live',
    connecting: ' Connecting. . .',
    requesting: ' Requesting. . .',
    errorTryAgain: 'Please try again',
    offline: 'Offline',
    error: 'Error',
    again: 'Try Again',
    record: 'REC',
    StepByStep: 'Step-by-Step Setup Guide',
    RiskLossData: 'Risk of Data Loss',
    RiskLossDataDescription:
      "Your decision to pause cloud-based archiving puts your sites at an increased risk of security incidents. Without the cloud storage, you no longer have access to your historical footage, AI detection, and analysis. To mitigate this risk, click 'Resume Recording' to ensure continuous monitoring.",
    cameraHardware: 'Camera Hardware',
    cameraProfile: 'Camera Profile',
    cameraProfileNcrDvr: 'NVR/DVR Profile',
    connection: 'Connection',
    securityTip: 'Security Tip',
    securityTipContent:
      "If you can't remember the username and password, we'll try to connect using your brand's default manufacturer setup. However, make sure to set up proper security credentials for your devices later to ensure bulletproof safety. 🔐",
    cameraNotFound: 'camera not found',
    errorFailed: 'ERROR: FAILING TO STREAM',
    actions: {
      edit: 'Edit',
      view: 'View',
      clone: 'Duplicate',
      live: 'Live',
      forgetDevice: 'Forget Device',
      setup: 'Setup Profile',
      editProfile: 'Edit Setup',
      profile: 'Camera Profile',
      duplicate: 'Duplicate',
      connect: 'Connect',
      troubleshot: 'Troubleshoot',
      stopRecording: 'Stop Recording',
      resumeRecording: 'Resume Recording'
    },
    form: {
      firmware: 'Device Firmware',
      model: 'Device Model',
      name: 'Device Name',
      address: 'Device Location (Physical Address)',
      cameraUsername: 'Camera Username',
      cameraPassword: 'Camera Password',
      nvrdvrUsername: 'NVR/DVR Username',
      nvrdvrPassword: 'NVR/DVR Password',
      tag: 'Tags',
      IP: 'URL',
      port: 'Web (HTTP) Port No.',
      streamPort: 'Stream (RTSP) Port No.',
      securePort: 'Secure Communications (HTTPS) Port No.',
      extraPort: 'Port No.',
      audioPort: 'Audio Port No.',
      serialPort: 'Serial Port No. (i.e. PTZ Controllers)',
      additionalCustomPorts: 'Additional Custom Ports',
      channel: 'NVR/DVR Channel Numbers',
      useSSL: 'Secure Sockets Layer (SSL)',
      enable: 'Enable',
      disable: 'Disable',
      reset: 'Reset Configurations',
      advanceSetup: 'Advance Connection Setup',
      personalizedSetup: 'Personalized Setup Assistance',
      cloneCamera: 'Duplicate Camera',
      editCamera: 'Edit',
      urlTip: 'Tip: Router’s Static IP',
      notFound: '-'
    },
    tooltip: {
      cameraUsernameHeader: "Finding Your Camera's Credentials 🛠",
      cameraUrl:
        'Tags are keywords or hashtags for devices, aiding in categorization and quick searches. Use them for efficient organization and streamlined search.',
      cameraUsernameDesc:
        "If your camera is on factory settings or has been reset, you won't need to worry about usernames or passwords; we'll retrieve the defaults from your manufacturer configs.",
      cameraURl:
        "Accepted Inputs: You can use a router's static IP address (e.g., 192.168.1.100), a domain name like mycamera.mydomain.com, a Dynamic DNS address (e.g., mycamera.ddns.net), or any internet URL for cameras using RTSP",

      WebHTTPPortDesc:
        "Web (HTTP) Port is designated for accessing the device's web interface, commonly used with IP cameras. The default configuration typically assigns it to port 80.",
      StreamRTSPPortDesc:
        'Dedicated to the Real-Time Streaming Protocol (RTSP), this port is used to stream video content from IP cameras and typically defaults to 554.',
      SecurePortDesc:
        'Data transmitted over a secure port is encrypted, offering protection against eavesdropping or data tampering. 443 is a common secure port number. ',
      ChannelPortDesc:
        "Sequential video input channels for connecting and recording video from surveillance cameras. For example, an 8-channel NVR allows up to 8 camera connections. Check your NVR or DVR's interface for details.",
      AudioPortDesc:
        'These ports, like 8000 and 9000, are commonly used for two-way audio in network cameras',
      SerialPortDesc:
        'In case your camera has serial ports for connecting to external devices or control systems, such as pan-tilt-zoom (PTZ) controllers.',
      ExtraPortsDesc:
        'can refer to any supplementary ports a device or service might use beyond the main or standard ports. These ports might be reserved for specific functions not covered by the primary ports or for handling additional data flow',
      SecureSocketLayerSSL: 'Secure Socket Layer (SSL)',
      tagTooltip:
        'Tags are keywords or hashtags for devices, aiding in categorization and quick searches. Use them for efficient organization and streamlined search.',
      SecureSocketLayerSSLDesc:
        "is a protocol used for securing data transmission over the internet. When enabled, it encrypts the data being sent so that it can't be easily intercepted or tampered with.",
      tryAgainMain:
        "Camera playing hide and seek? Let's find it ! \n 1) Make sure it's powered up and near by. \n  2) Is it on good terms with your router? Check their connection. \n  3) Clear the path – turn off firewalls or VPNs that might be in the way. \n 4) Play detective – enter its IP address in a browser to see its status.\n ",
      tryAgainNeedHelp: 'Still elusive?',
      tryAgainReachOut: 'Knowledge Center',
      tryAgainBridge: ' is ready to crack the case!',
      offlineMain:
        "Port Connection Issue Detected: Your camera's on, but the streaming ports are playing hard to get. Time to revisit your port forwarding setup. ",
      offlineNeedHelp: 'Need help?',
      offlineReachOut: 'Reach out',
      offlineBridge: " and we'll bridge the gap in no time! "
    },
    cameraConnect: {
      workspaceHeader:
        'The journey to a safer tomorrow is yours to lead. First, a workspace tune-up. 🔌',
      workspaceTooltip:
        "Imagine this - workspaces are your distinct personal 'game zones', each with unique plans, devices, and teammates chosen by you. Feel like building a cozy space at home and inviting your family to join? Or perhaps diving into a workspace at your job, taking on the role of the Chief of Security for Business? Thrilling, isn't it?\"",
      nextSetup: 'Next, Setup Cameras',
      nextPortForward: 'Next, Port Forwarding',
      stepsGuide: 'Step-by-Step Setup Guide',
      advanceSetup: 'Advance Connection Setup',
      affordableVigilance:
        'Making the Jump: How to Seamlessly Onboard onto Cloud Video Security ☁️',
      harnessPowerAi: 'Opt for 30, 60, and 90 Days of cloud safety!',
      seamlessIntegration: 'Seamless Integrations',
      flexibleArchiving: 'Precision Detection: Next-Gen AI \n' + '& Video Intelligence',
      getAssistance: 'Get Setup Assistance Here 🚀',
      chatGptGuid: 'ChatGPT Guide ',
      rapidAIAnalysis:
        'Harness the power of AI to detect people, objects and events, swiftly sifting through hours of video in mere minutes',
      enhancedMonitoringLayout:
        'Optimize your monitoring coverage by creating nested maps & floor plans',
      unsureIp: 'Unsure about your IP?',
      ipAddressMarketHeader: 'Onboard with a Smile! We Cover Installation. Call for Help! 🤙🏼 ',
      ipAddressMarketSubHeader:
        "Reach out, and we'll bridge the gap in no time! Our savvy team will swing by your location and get everything up and running smoothly.",
      explorationAwaits: 'Peek at the resources below to discover yours!',
      likePro: 'Feeling like a pro?',
      skipAhead: "If this isn't your first rodeo, skip right to the final step.",
      cameraNotFound: "Can't spot your camera?",
      quickAssistance: "Reach out, and we'll bridge the gap in no time!",
      tailoredSetup: 'Personalized Setup Assistance',
      seamlessCompatibility: 'Curious to know how we flawlessly sync with any camera you own?',
      ONVIFExplained: 'ONVIF Explained',
      frequentlyQuestion: 'FAQ: Data Privacy 🛡️',
      accessFootage: 'Who has access to my video footage?',
      monitoringHeader: 'Seamless Onboarding, Free of Charge',
      monitoringDesc:
        "Reach out, and we'll bridge the gap in no time! Our savvy team will swing by your location and get everything up and running smoothly.",
      Q: 'Q',
      A: 'A',
      hintPortForward: 'Hint: We asked ChatGPT to explain how to port forward cameras to a router',
      readAnswerPortForward: 'Read the answer.',
      security:
        'Unequivocally, no one. Your footage is fortified with {app} and is securely anchored on the Amazon AWS cloud, leveraging the pinnacle of encryption technologies. Your privacy is paramount to us.',
      endEncryption: 'end-to-end encryption',
      guideSecurity: 'Guide to Security',
      pricing: 'Pricing',
      DigitalSafety: 'Digital Safety ',
      SecureAWS:
        "Stored on the trusted Amazon AWS cloud, we use a zero-trust model, which, in essence, means it's built to be impenetrable. It's not just hard to crack; it's designed to be impossible.",
      CameraPortingSimplified:
        "Intro to Camera Ports: Setting up your camera? Remember, you'll need to port forward two key ports!",
      WebPortex: 'Web Access (HTTP) Port: ',
      VideoStreamex: 'Video Stream (RTSP) Port: ',
      WebGateway:
        "{WebPortex}: Imagine this as your camera's digital doorway, leading you straight into its web interface. Commonly used in IP cameras, right from the start, many cameras have this set to the familiar port 80.",
      StreamPort:
        "{VideoStreamex}t: All about delivering those live video feeds! It uses the Real-Time Streaming Protocol to make it happen. Most times, you'll find it's already set to port 554 when you start!",
      FreeOnboarding: 'Seamless Onboarding, Free of Charge',
      OnsiteSetup:
        "Reach out, and we'll bridge the gap in no time! Our savvy team will swing by your location and get everything up and running smoothly.",
      labelSelectManufacture: 'Search by Brand, Part Number or Model *',
      hintCameraProfile:
        'Pro Tip: Rock your {nvrSetup} Port forward it on your router just once, then easily add multiple cameras using their sequential channel numbers.',
      nvrSetup: 'NVR/DVR setup!',
      dropDownPlaceHolder: 'Click here..',
      staticIp: {
        GetStaticIP: 'Network Setup 🌐 Steady internet plus static IP equals camera ready!',
        ConfigurePort: 'Router Setup🔧 Enable Port-forwarding on your router for camera feeds.',
        portForwardSkip: 'Good to go. Both ports are port-forwarded on my router.',
        nextStep: 'Next Step ',
        guidToVideo: 'I’m Puzzled. \n Guide me through it. ',
        watchNow: 'Watch Now',
        hintSearchPort: "Hint: Search 'how to port forward on my {{Your Router Name}}' on Google.",
        ipAddress: 'My Router’s Static IP',
        hintIp: 'Hint: This field cloud also be a Domain Name, Dynamic DNS (DDNS), or a URL',
        ipTooltip:
          "To seamlessly integrate your cameras with our platform, we need to locate their digital address on your network. It's essential for your router to have a static IP address. This is like assigning a permanent digital address to your home or business on the internet",
        skip: 'skip',
        jumpToPricing: 'Jump to Pricing',
        paymentMethod: 'Next, Payment Method',
        masterImmediately: 'Master the Basics in 3 Minutes',
        step1VerifyIp: '(1/3) Verify IP Address',
        portForwarding: '(2/3) Port Forwarding',
        cameraProfile: '(3/3) Camera Profile',
        verifyConfigureIp:
          "I confirm that I've set up port forwarding on my router and jotted down all the port numbers. Onward to the next step! 🚀",
        powerDevices:
          'Camera Check 🎥 Power on and keep your devices nearby for a seamless installation.',
        hintConfigureIp:
          "Hint: Open a web browser → Input your router's IP address → Log in using your router’s username \n" +
          'and password→ Navigate to "Port Forwarding", "Virtual Server", or "Applications" \n' +
          '(terminology varies by router brand).',
        NextCameraProfile: 'Next, Camera Profile',
        SearchCriteria: 'Search by Brand, Part Number or Model',
        maps: 'Maps',
        connect: 'Connect',
        clone: 'Duplicate',
        forget: 'Forget'
      },
      goToStreaming: 'Monitoring Dashboard'
    },
    label: {
      updateAvatar: 'Avatar Updated Successfully',
      removeAvatar: 'Avatar Removed Successfully'
    },

    drawer: {
      updatedSuccessfully: 'Camera Updated Successfully',
      createdSuccessfully: "'{name}' Created Successfully"
    },
    details: {
      cameraOverview: 'Camera Overview',
      permissions: 'Permissions',
      dataPrivacy: {
        title: 'Data Privacy 🛡️',
        content:
          "Your video footage is sacrosanct, and we are unwavering in our commitment to safeguarding your privacy. Safeguarded within the highly secure {data}, our system employs an ironclad zero-trust model, rendering breaches a virtual impossibility. Your data isn't merely resilient; it's fortified to be impervious, ensuring you the unshakable peace of mind you rightfully demand.",
        aws: 'Amazon AWS infrastructure',
        digitalPrivacy: 'Digital Privacy'
      }
    },
    errorMessages: {
      subscriptionUpdateFailed: 'Subscription Error',
      cameraDataWrong: "hey, It looks like you'll need to configure your camera ports.",
      cameraCreationFailed: 'Camera Creation Failed',
      exportClips: 'Export Clips'
    },
    messages: {
      subscriptionUpdated: 'Subscription Update',
      subscriptionUpdatedDetail:
        "Excellent! Please don't leave this page—we're in the process of connecting your cameras with your upgraded subscription. A minute or two is all we need.",
      removedSuccessfully: 'Camera Removed Successfully'
    },
    delete: {
      title:
        'Ready to wipe out {name} and its entire cloud collection (recordings, clips, and analytics)? Type in the device name to make it official.',
      description:
        "By selecting 'Delete', I acknowledge that I am initiating an irreversible action. This will permanently disconnect Arcadian Orbit Inc.'s services for my device, leading to the immediate and irretrievable loss of all recorded footage and all associated hardware data.",
      deleteButton: 'Delete',
      inputLabel: 'Type \"{name}\" *'
    }
  },

  //billing
  billing: {
    title: 'Billing',
    payment: {
      title: 'Payment Method',
      newPaymentTitle: 'New Payment Method',
      updatePaymentTitle: 'Update Payment Details',
      updatePaymentDescription:
        '{data}, your peace of mind is priceless!\n' +
        'Secure continuous protection for your spaces by updating your payment details.',
      selectCard: 'Select Card',
      labels: {
        fullName: 'Full Name',
        zipCode: 'Zip Code',
        cardNumber: 'Card Number'
      },
      actions: {
        addCard: 'Add Card',
        credit: 'Credit Card',
        google: 'Google Play',
        bank: 'Bank'
      },
      messages: {
        goodNews: 'Good News',
        footer: 'Commitment-free zone: bow out anytime without any fees.',
        drawer:
          "We require a valid payment method on file. {news} Your card won't be changed until {date} if you need to cancel at any time, you have that flexibility."
      }
    },
    ask: {
      title: 'Ask Us Anything ☕️',
      messages: {
        subtitle:
          'Need clarity on plans and billing? Our team is at your service and eager to assist!'
      }
    },
    methods: {
      title: 'Payment Method',
      actions: {
        add: 'Payment Method',
        update: 'Update',
        delete: 'Delete',
        replace: 'Replace'
      }
    },
    invoices: {
      title: 'Invoices',
      noData: 'Nada! Zilch! No invoices in sight.',
      labels: {
        invoice: 'Invoice',
        all: 'All'
      },
      actions: {
        download: 'Download'
      }
    },
    invoiceDetail: {
      invoiceStatus: {
        paid: 'Paid',
        overdue: 'Overdue',
        error: 'Failed',
        open: 'Open',
        freeTrial: '1 Month Free'
      },
      period: 'Subscription Period',
      monthly: 'Monthly',
      yearly: 'Yearly',
      sixtyDays: '60 Days',
      ninetyDays: '90 Days',
      days: 'Days',
      minutes: 'Minutes',
      hours: 'Hours',
      vat: 'Vat',
      subtotal: 'Subtotal',
      total: 'Total',
      download: 'Download',
      invoice: 'Invoice'
    },
    address: {
      title: 'Addresses',
      successUpdate: 'Updated!'
    }
  },

  //payment
  payment: {
    onboarding: {
      title: 'Payment Method'
    },
    seatsTooltip: 'Seats Open for Camera Pairing'
  },

  //price people
  pricePeople: {
    pricing: 'Pricing',
    people: 'People',
    cameras: 'Cameras',
    peopleHeader:
      'The power of collective vigilance! Bring your trusted contacts into your workspace and enhance monitoring.',
    peopleSlideQuestion: ' If I invite new members to my space, what will they have access to?',
    peopleSlideAnswer:
      "New members will initially have a {viewOnly} access to the monitoring dashboard. However, our platform boasts a versatile {peoplePermission} module enabling you to craft custom roles, ensuring you have full control over each member's access level within your workspace. ",
    viewOnly: 'view-only',
    peoplePermission: 'People and Permissions',
    goToMonitoring: 'All Set! Monitoring Dashboard',
    usersSeats: 'Seats for Users',
    usersSeatsPrefix: 'Up to',
    billingCycle: 'Billing Cycle',
    yearlySavings: 'Yearly Savings',
    totalAfterSaving: 'Total After Savings',
    off30: '30% OFF',
    yearlySavingsTooltip: 'Unlock a 30% discount by transitioning to an annual billing cycle.',
    freeTrial: 'Free Trial',
    yourPlan: 'Your Plan',
    cloudStorage: 'Cloud Memory',
    subscription: 'Subscription',
    subtotal: 'Subtotal',
    taxes: 'Taxes',
    total: 'Total',
    dueOn: 'Due on',
    dueNow: 'Due Now',
    discountApply: 'Discount Applied!',
    discountCode: 'Discount Code',
    days365: '365 Days',
    days60: '60 Days',
    days30: '30 Days',
    days90: '90 Days',
    day: '1 Day',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly',
    week: 'Weekly',
    month: 'Monthly',
    year: 'Yearly',
    secureMonitoring: 'Ensure uninterrupted monitoring by adding a payment method.',
    freeRide: 'And hey, enjoy the free ride until',
    uninterruptedVigilance: 'To keep your vigilance uninterrupted, add a payment method.',
    freeToCancel: 'Your terms, always: feel free to {cancelAnyTime} without any extra charges.',
    cancelAnyTime: 'cancel anytime',
    missingPayment: 'Missing Payment Method',
    edit: 'Edit',
    send: 'Send',
    pricingCalculate: 'Pricing Calculator 🍕',
    pricingCalculateLink: 'Visit our website',
    pricingCalculateContent:
      'Say hi to our pricing calculator! Simply enter a payment method to have your accurate totals calculated – all smiles, no hassle. Changed your mind? Cancel anytime.'
  },

  //monitoring
  monitoring: {
    title: 'Monitoring Dashboard',
    messages: {
      looking: "I'm looking for...",
      aiDetectionTitle: 'AI Detection Search',
      ExpandTooltip: 'Expand',
      snapShotTooltip: 'Take Snapshots',
      MinimizeTooltip: 'Minimize',
      aiDetection:
        'our search parameters for regular alerts. Elevate your surveillance strategy, embracing precision and proactive oversight.',
      aiHeatMapTitle: 'AI Heatmaps',
      aiHeatMap:
        'are not just tools — they are strategic assets designed to drive revenue for your growing businesses. Harnessing visual intelligence, AI Heatmaps sharpen space dynamics, converting raw footage into pivotal insights on traffic patterns, hotspots, unwanted visitors, stressful zones, and customer behaviors.',
      clock:
        'Rewind Multiple Cameras to Date: Easily sync playback timelines across cameras to a specific date.',
      aiHeader: 'Events:',
      aiDescription:
        'Coming soon: X-Ray vision for your space! Easily discern familiar faces from unknown visitors, amplifying loss prevention alerts in your security arsenal.',
      gradiantHeader: 'AI Heatmaps:',
      gradiantDescription:
        'AI Heatmaps Preview: Experience your raw footage converting into activity hotspots, unearthing new revenue-generating possibilities in your busiest zones',
      gradiantDescriptionDisabled:
        "Exciting news: You're on the early access list for AI Heatmaps! Transform raw footage into revealing insights about customer behavior, preventing losses. To find out release dates, schedule a demo at sales{'@'}arcadian.ai.",
      box: 'Puzzle View: No Rules—Resize,\nDrag & Drop freely.',
      list: 'List View: All Your\n Cameras, One Place!',
      gridsLayoutTooltip: '{dims} Preset Grid View',
      successfulRemoveClip: 'Deleted!'
    },
    welcome: 'Welcome {name} !',
    dive: "Ready to roll? You're on the brink of the Cloud ☁️",
    trialEnded:
      'Continue enjoying uninterrupted peace of mind. Ensure your workspace remains secure by updating your payment method.',
    clips: 'Clips',
    heatmap: 'Heatmap',
    until: 'Until',
    RemainingBalance: 'Remaining Balance',
    balanceTooltip:
      "Seeing a balance? It's due to having fewer connected devices on your plan now. This amount will be credited to reduce your upcoming bill. Enjoy!",
    since: 'Since',
    type: {
      maps: 'Maps',
      cameras: 'Cameras',
      connectors: 'Connectors',
      error: 'Error'
    },
    table: {
      header: {
        device: 'Device',
        status: 'Status',
        location: 'Location',
        people: 'People',
        tag: 'Tags'
      },
      actions: {
        profile: 'Profile',
        clone: 'Duplicate',
        remove: 'Forget Device'
      }
    },
    actions: {
      learning: 'Get Setup Assistance Here🚀',
      back: 'Back to Onboarding',
      live: 'Live',
      all: 'All',
      recording: 'REC',
      error: 'Offline',
      tryAgain: 'Try Again',
      connecting: 'Connecting...',
      offline: 'Offline',
      disconnected: 'Disconnected',
      updatePayment: 'Update Payment',
      addPayment: '+ Payment Method',
      Downloading: 'Downloading...',
      LiveURLs: 'Live URLs',
      Archived: 'Archived',
      SortByNew: 'Sort By: New',
      cloudConnect: 'Cloud Connectors',
      AIDetect: 'Orbit, The AI Detection '
    },
    ads: {
      title: 'Frequently Asked Question',
      question: '{q}: Who has access to my video footage?',
      answer:
        '{a}: Unequivocally, no one. Your footage is fortified with {encryption} and is securely anchored on the Amazon AWS cloud, leveraging the pinnacle of encryption technologies. Your privacy is paramount to us.',
      encryption: 'end-to-end encryption'
    },
    remove: {
      title: 'Taking out {title} means its stored recordings and analytics will be wiped clean.',
      action: 'Delete Camera'
    },
    clipSection: {
      title: 'Incident Insights',
      content:
        'Dash to your monitoring and grab the standout events from your players. Your footage, once downloaded, is sorted into clips for hassle-free access and future reference!'
    },
    heatmapSection: {
      title: 'Data, Data, Novel Untapped Data',
      content:
        'Unlock the potential of your surveillance data with AI heatmaps, revealing key insights like peak activity times, customer dwell times between aisles, and checkout line efficiency.'
    },
    tooltips: {
      clipDownload: 'Download Now!',
      clipRemove: 'Permanently Delete File'
    },
    selector: {
      empty: 'No devices? Jump into onboarding to set up your cameras.'
    }
  },

  //permissions
  permissions: {
    title: 'People',
    rolePermissions: 'Roles & Permissions ',
    newRole: 'New Role',
    spaceOverview: 'Space Overview',
    peopleRoles: 'People & Roles',
    invitePeople: 'Invite People',
    invitePeopleTooltip:
      "This permission entrusts your chosen teammates with the ability to expand your workspace circle. Handle with care - each new member can influence your workspace's security!",
    edit: 'Edit',
    clone: 'Duplicate',
    suspend: 'Suspend',
    delete: 'Delete',
    hidden: 'Hidden',
    viewOnly: 'View only',
    fullControl: 'Full control',
    newRoleDesc:
      "Role is the digital key in the workspace, unlocking doors to various adventures. It sets a member's access level and controls what they can explore or modify.",
    roleName: 'Role name',
    createMode: '🖌️ creator mode',
    reviewMode: '🧐 review mode',
    editMode: '✏️  edit mode',
    editModeSimple: 'edit mode',
    workspacePermissions: 'Workspace Permissions',
    workspaceOverview: 'Workspace Overview',
    descriptionTooltip:
      'Seen as tooltips, this field acts as your quick guide to identifying roles and permissions within your workspace.',
    workspaceOverviewTooltip:
      'This screen is your one-stop hub for all things workspace. It includes a snapshot of your members and connected devices, lets you keep tabs on activity logs, and helps you stay ahead of the game with a glimpse of upcoming payments.',
    peoplePermissions: 'People & Permissions',
    peoplePermissionsTooltip:
      "This is where your workspace comes alive! Here, you'll find your team members, their roles, and their varying levels of access.",
    billingInvoicing: 'Billing & Invoicing',
    billingInvoicingTooltip:
      'Your financial control panel, where all invoice and payment method matters are handled. This is your one-stop shop for all monetary transactions.',
    Subscription: 'Subscription',
    SubscriptionTooltip:
      " This permission item empowers your selected teammates to alter your subscription plan, either upgrading or downgrading, using the active payment method on file. It's a powerful tool, so handle with care!",
    monitoringPermissions: 'Monitoring Permissions',
    liveStream: 'Live Stream',
    liveStreamTooltip:
      "Think of this as your digital surveillance hub, presenting live video feeds at your command. Watch-only members can sit back and enjoy the show, while those with full-control can play around with the dashboard setup. Navigate wisely - your workspace's security depends on it!",
    deviceSetup: 'Device Setup',
    deviceSetupTooltip:
      'This permission allows your handpicked teammates to connect, troubleshoot, disconnect, and fiddle with all your devices configurations. Keep in mind, each alteration could impact your live stream monitoring. Tread carefully!',
    clipsHistoricalFootage: 'Clips & Historical Footage',
    clipsHistoricalFootageTooltip:
      "This permission allows your chosen team members to dive into the playback modes, download clips, and export videos. It's akin to possessing a time machine for your feed!",
    mapsDeviceLocations: 'Maps & Device Locations',
    mapsDeviceLocationsTooltip:
      "Nestled in your monitoring dashboard, this feature uncovers the geographical locations of your devices and cameras, giving your selected members a bird's eye view of your security setup.",
    timestampsEvents: 'Timestamps & Events',
    suspended: 'Suspended',
    RestoreAccess: 'Restore Access',
    EditRole: 'Edit Role',
    NoticeSuspendHeader: 'Access Interruption Notice',
    NoticeSuspendDesc:
      "Heads up! Suspension isn't a light matter. It means complete cutoff - the suspended users will lose all access to your workspace. Proceed with caution, and know that the power of restoration is always in your hands. They can rejoin the game at any moment, all you have to do is click on ",
    NoticeEditedHeader: 'Game-Changing Permissions',
    NoticeEditeDesc:
      "Changing permissions in a role will ripple through all members of the authority group. Handle with care, it's game-changing!",
    RemoveTeamDesc: 'Ready to remove team \n' + 'in the workspace?',
    RemoveTeam: 'Yes, Remove Team',
    RemoveUser: 'Ready to part ways with {user} \n' + 'in the {workspace}?',
    teamStatusChange: 'team status was changed',
    teamWasDelete: 'team was deleted!',
    userDeleted: 'user was deleted!',
    deleteRole: 'Delete Role',
    suspendRole: 'Suspend {role} Role',
    suspendUser: 'Suspend {user}',
    confirmText: 'To confirm, type in the role name below.',
    guideMessageDelete:
      'By clicking {delete}, I acknowledge that I will irreversibly remove all workspace users linked to this role. Proceed with caution.',
    successRole: 'new role was created!',
    successUpdateRole: ' role was updated!',
    sidebarInvitedPrefix: 'hey! Join {workspaceName}',
    sidebarLongNamePrefix: 'hey! Join {workspaceName}',
    table: {
      header: {
        fullName: 'Full name',
        status: 'Status',
        onlineStatus: 'Status',
        email: 'Email',
        phone: 'Phone Number',
        lastSwitchedAt: 'Last Active',
        role: 'Role',
        inviteTooltip: 'Quickly add team members'
      },
      statuses: {
        title: 'Member Status',
        tooltip:
          "Member Status: Represents a user's condition in the platform — 'Active', 'Invited'.",
        pending: 'Invited',
        joined: 'Active',
        termsRequired: 'Terms Required',
        withdrawn: 'With Drawn',
        notFound: 'Not Found'
      },
      rules: {
        title: 'Role',
        tooltip:
          'A designation for workspace members, setting their access levels and dictating what they can view or modify in the platform.',
        coPilot: 'Co-pilot',
        captain: 'Captain',
        member: 'Member',
        transfer: 'Transfer'
      },
      actions: {
        profile: 'Profile',
        remove: 'Remove',
        removeAccess: 'Restore access',
        suspend: 'Suspend',
        reSend: 'Re-send Invite',
        transfer: 'Transfer Ownership',
        confirmRemove: 'Yes, Remove User'
      }
    },
    profile: {
      header: {
        contact: 'Contact',
        security: 'Security'
      },
      labels: {
        littleTitle: 'Little Nudge',
        accessNotice: 'Access Interruption Notice',
        memberSince: 'Member since'
      },
      actions: {
        review: 'Review Permission',
        restore: 'Restore Access',
        forceEmail: 'Force Verification',
        resetAuthentication: 'Reset Authentication',
        forceMultiFactor: 'Force Multi-Factor Authentication'
      }
    },
    messages: {
      successSuspendUser: 'user suspended successfully!',
      successReInviteUser: 'user re-invite successfully!',
      returnAccessUser: 'user access is return',
      changeRole: 'user role changed successfully',
      inviteMessage:
        'Your invitee is playing a bit hard to get? Try sending them another invitation and get them on board! {link}',
      accessNotice:
        "Heads up! Suspension isn't a light matter. It means complete cutoff - the suspended users will lose all access to your workspace. Proceed with caution, and know that the power of restoration is always in your hands. They can rejoin the game at any moment, all you have to do is click on {link}",
      tooltips: {
        coPilot:
          "Think of them as your ace managers or power-users, keeping an eye on live streams and juggling day-to-day tasks. Just one thing - they're kept away from the finance stuff.",
        member:
          "Also known as Viewers, these are invited folks who have just enough access to see what's going on. They're more like guests in the workspace - they can look, but can't touch much!"
      },
      resendInviteSuccess: 'Sent!',
      bulkInviteSuccess: 'Sent!',
      removeUserSuccess: 'Removed. The user no longer has entry to your workspace.',
      changeUserTeamSuccess: 'Saved!'
    },
    errorMessages: {
      isPhoneNumber:
        '{phoneNumber} Invalid. Make sure you entered a valid US or CA cellphone number. ',
      phoneNotFound: 'oops! The phone number was not found',
      QuotaIsLocked:
        "hey, we've noticed a subscription issue. Please add a valid payment method. If the problem persists, contact us at +1 (800) 288-9192 or sales{'@'}arcadian.ai. We're here to help!",
      SubscriptionProblem:
        "hey, we've noticed a subscription issue. Please add a valid payment method. If the problem persists, contact us at +1 (800) 288-9192 or sales{'@'}arcadian.ai. We're here to help!",
      limitationProblem:
        "Uh-mazing! Popular workspace alert — you've reached the limit of {n} users per space. To unlock new invites at no cost, just email us at sales{'@'}arcadian.ai.",
      phoneExist: 'Whoops! {phone} is already part of the workspace.'
    },
    tooltips: {
      captain: {
        name: 'Captain',
        description:
          'Captains are exclusive business owner of the workspace, blessed with unrestricted access to all its bells and whistles.'
      },
      'co-pilot': {
        name: 'Co-Pilot',
        description:
          'Co-Pilots serve as your elite operational managers or advanced users, vigilantly monitoring live streams while adeptly handling daily responsibilities. Their access spans all areas except billing and finance.'
      },
      member: {
        name: 'Member',
        description:
          'Members, also known as Viewers, have minimal interaction capabilities; they are limited to viewing your streams only.'
      },
      guest: {
        name: 'Guest Captain',
        description:
          'Guests—think of them as temporary “ghosts” or demo explorers—can roam and interact with everything, but their changes vanish into thin air and won’t be saved!'
      },
      'guest captain': {
        name: 'Guest Captain',
        description:
          'Guests—think of them as temporary “ghosts” or demo explorers—can roam and interact with everything, but their changes vanish into thin air and won’t be saved!'
      },
      usersOverview: {
        pending: '{phone} has a pending invitation to join your workspace.',
        joined: '{fullName}' // todo check with mari
      }
    },
    modals: {
      remove: {
        title: 'Ready to remove {fullName} in {workspaceName}?',
        cancel: 'Cancel',
        ok: 'Yes, Remove User'
      },
      warningInvite: {
        title: 'Sensitive Data Access',
        description:
          'Hey! 🚀 Inviting someone as a Guest Captain gives them superpowers with elevated permissions, including the ability to view, modify, or manage sensitive data.',
        accept: 'Invite Anyway',
        cancel: 'Go Back'
      }
    },
    permissionsList: {
      WorkspaceOverview: 'Space Overview & Logs',
      PeopleAndPermissions: 'People & Permissions',
      BillingAndInvoicing: 'Billing & Subscriptions',
      LiveStream: 'Live Stream',
      DeviceSetup: 'Device Setups & Configs',
      ClipsHistoricalFootage: 'Historical Footage & Clips',
      CustomerAnalyticsAndAIHeatmaps: 'Proactive AI Alerts, Video Intelligence Suite'
    },
    dropDownDescriptions: {
      captain:
        'Exclusive business owner of the workspace, blessed with unrestricted access to all its bells and whistles.',
      'co-pilot':
        'Operational managers or power users, vigilantly monitoring live streams while adeptly handling daily responsibilities. Their access spans all areas except billing and finance',
      member:
        'Members, also known as Viewers, have minimal interaction capabilities; they are limited to viewing your streams only.',
      guest:
        'Guests—think of them as temporary “ghosts” or demo explorers—can roam and interact with everything, but their changes vanish into thin air and won’t be saved!',
      'guest captain':
        'Guests—think of them as temporary “ghosts” or demo explorers—can roam and interact with everything, but their changes vanish into thin air and won’t be saved!'
    }
  },

  //onboarding
  onboarding: {
    steps: {
      workspace: 'Workspace',
      cameras: 'Cameras',
      pairing: 'Pairing',
      maps: 'Maps',
      pricing: 'Pricing',
      people: 'People',
      liveStream: 'Live Stream!'
    },
    workspace: {
      welcome: 'Welcome {data}! Tell us more about yourself.',
      tooltipWorkspaceName:
        "Picture this: Workspaces as personalized 'game zones'—each crafted with your chosen strategies, tools, and team. Whether it's a cozy home setup with family or leading as the Chief of Security at work, the excitement is unparalleled.",
      label: {
        name: 'Workspace Name',
        namePlaceHolder: 'Your location name',
        selectTimePlaceHolder: 'Select hours',
        industry: 'Industry',
        address: 'Address',
        yes: 'Yes',
        notReally: 'Not Really'
      },
      addressQuestion: 'Is this location designed for direct customer engagement? *',
      hoursOperations: 'Hours of Operations',
      hoursOperationsTitle: 'Proactive Alerts: Tailored to Your Business Hours',
      days: {
        sat: 'Saturday',
        sun: 'Sunday',
        mon: 'Monday',
        tue: 'Tuesday',
        wen: 'Wednesday',
        thu: 'Thursday',
        fri: 'Friday'
      },
      industry: {
        food: 'Food & Beverage',
        convenience: 'Convenience Store',
        clothing: 'Clothing & Apparel',
        furniture: 'Furniture & Home Appliance',
        sport: 'Sporting Goods',
        book: 'Book Stores, Musical Instruments',
        hotels: 'Hotels & Accommodations',
        travel: 'Travel',
        health: 'Health & Wellness, Personal Care',
        entertainment: 'Entertainment, Theatres, Venues',
        education: 'Education',
        finance: 'Finance ',
        construction: 'Construction ',
        cars: 'Cars',
        electronics: 'Electronics & Appliance, Technology ',
        home: 'Home Services',
        gift: 'Gift Shop',
        pet: 'Pet Supplies',
        other: 'Other'
      },
      successfullyChangeAddress: 'Your address has been successfully changed',
      copyToEveryDay: 'Copy to every day',
      NexStep: 'Next, Device Pairing',
      title: "The voyage to a safer tomorrow starts with you. Let's connect the dots!",
      inputLabel: 'Label your current whereabouts.',
      inputPlaceholder: 'Workspace Name',
      deviceTitle: 'Choose a device to pair:',
      deviceNotList: 'Device not listed? ',
      requestHere: 'Request Here',
      workspaceTooltip:
        "Imagine this - workspaces are your distinct personal 'game zones', each with unique plans, devices, and teammates chosen by you. Feel like building a cozy space at home and inviting your family to join? Or perhaps diving into a workspace at your job, taking on the role of the Chief of Security for Business? Thrilling, isn't it?\"",
      nextSetup: 'Next,  Camera Connect',
      bridge: 'via Bridge',
      nvr: 'via NVR/DVR',
      rtsp: 'via RTSP/HTTP',
      onvif: 'via Universal ONVIF ',
      arcadian: 'ArcadianAI'
    },
    pairing: {
      title: "Now, let's connect the dots. ",
      next: 'Skip to Pricing',
      selectBrand: 'Choose a brand to pair *'
    },
    inviteSuccess: 'Invites Sent!',
    alert: {
      title: 'Connection Interrupted',
      content: {
        header:
          'Your camera seems to have zoned out for a moment.' +
          "\n Let's reel it back in! Here are 4 immediate checks to bring it back online:",
        checks: {
          a: "1. Ensure the camera's power source is secure.",
          b: '\n 2. Double-check its connection to the router.',
          c: '\n 3. Turn off potential network barriers, like firewalls or VPNs.',
          d: "\n 4. Enter the camera's IP address in a web browser to verify its operational status."
        },
        footer: 'For deeper insights, consult our {link}.'
      },
      actions: {
        guide: 'troubleshoot guide',
        again: 'Again',
        support: 'Connect with an expert'
      }
    },
    tutorial: {
      description1: `Today, we embark on a transformative journey, unlocking the true potential of your existing physical security setup. Our guide will demystify essential tech concepts for you: firstly, the {staticIp}, the unique, unchanging digital home for your camera amidst the vast expanse of the internet. Next, we'll delve into {portForward}, a pivotal method letting external devices access your camera. We’ll guide you through the heart of your network, the {routerPanel}—a digital cockpit accessed via a web browser, where you hold the reins of all settings. Along the way, we’ll introduce you to {gettingPing}, a simple yet crucial test ensuring your camera’s seamless connectivity on the network. `,
      description2: `With the tools in hand, which include a reliable internet connection, a static IP address, and of course, your trusty security camera, by the culmination of this tutorial, you'll not only have your cameras streaming live on our platform but also fortified them with the best security practices in the realm. Ready to elevate your security game? Let’s dive right in!`,
      staticIp: 'Static IP Address',
      portForward: 'Port Forwarding',
      routerPanel: `Router's Admin Panel`,
      gettingPing: `Getting a Ping`
    },
    actions: {
      next: 'Setup finished! Next'
    }
  },

  //workspace
  workspace: {
    transferOwnership: 'New Captain 🚢',
    TransferOwnershipDesc:
      'Choose a trusted friend or colleague to take the helm as the Captain of your workspace. With this title comes full control and exclusive ownership.',
    TransferOwnershipDesc2:
      'Once you hand over the keys, your role as the owner will end. Make sure your Captain is ready to steer the ship!',
    workspaces: 'Workspaces',
    overview: 'Space Overview',
    billing: 'Billing',
    createOn: 'Created on',
    createdBy: 'Created by',
    transferOwner: 'Transfer Ownership',
    redLock: 'Code Red Lock',
    spaceLogo: 'Space Logo',
    spaceDesc:
      'Let’s personalize your workspace! Upload a 40x40 px company logo with a transparent background for optimal display. ',
    upload: 'Upload',
    freeTrial: '1 Month Free',
    SubscriptionExpired: 'Expired',
    LogoUpdated: 'Logo updated. ',
    peopleTitle: 'People',
    upcomingTitle: 'Upcoming',
    BillingPayments: 'Billing & Payments',
    people: 'People',
    invite: 'Invite',
    add: 'add',
    nominate: 'Nominate',
    personalData: 'Personal Data Notice',
    personalDataDesc:
      "You're about to pass over the full ownership of your account, which includes all linked credit cards, billing details, and related data. This decision carries considerable weight as it's irreversible. Please, move forward only if you have full trust in the new owner and understand the implications thoroughly",
    members: 'Members',
    invitePeople: 'Extend Workspace Invitations',
    inviteDesc:
      'Extend a digital welcome! Quickly add team members by entering their phone numbers below. You control their roles and camera permissions.',
    inviteWith: 'Invite by phone number',
    inviteSuccess: 'Invite message sent for user',
    select: 'Select',
    YouNominated: 'You are nominated ',
    pending: 'Invited',
    sendNow: 'Send Now',
    sendInvite: 'Send Invite',
    member: 'Member',
    inviteHint: 'If you want to add a number, please include the initial code first.',
    successAdd: 'users added!',
    hintInvite: 'Example: +1 (209) 999 9999',
    workspaceDeleteSuccess: 'workspace was delete successfully',
    deviceDeleteSuccess: 'device was delete  successfully',
    billingInvoicing: 'My Billing & Invoicing ',
    label: {
      WorkspaceName: 'Workspace Name',
      Description: 'Description',
      typeHere: 'Type Here',
      nameSuccess: 'Updated!',
      errormsg: 'Oops! Something went wrong. ',
      descSuccess: 'Updated!'
    },
    actions: {
      save: 'Save'
    },
    devices: {
      title: 'Devices',
      connect: 'Watch how-to connect tutorial',
      bridgeTitle: 'Cameras via Bridge'
    },
    upcoming: {
      title: 'Upcoming',
      nextPayment: 'Next Payment',
      description:
        "You have the flexibility to upgrade or cancel your plan at any time, and the best part is that it's \n" +
        'completely free of charge.',
      today: 'Today',
      monthly: 'monthly',
      cta: 'Billing & Invoicing',
      training: 'Training',
      autoRenewal: 'Auto Renewal',
      freezeTitle: 'Pause Upcoming Payments',
      freezeSubtitle:
        'To deactivate auto-renewal, please enter the name of your Workspace below for confirmation.',
      workspaceName: 'Type “{data}” ',
      getDiscountedPricing: 'Get Discounted Pricing',
      turnOff: 'Turn Off',
      freezeNote:
        "I acknowledge that by hitting 'Turn Off', I am venturing into a digital abyss, halting my subscription and cutting off recurring payments. This grave decision puts me on a precipice, fully aware that failing to retreat will lead to the catastrophic vanishing of all my treasured video footage and personal data within the next month, a loss akin to a personal data apocalypse.",
      riskLostData: 'Risk of Data Loss',
      riskLostDataText:
        'Please note that choosing to keep your auto-renewal off places you at a crucial crossroads. This important decision, if not reconsidered, could result in the total and irreversible loss of all your valued video footage and personal data within the next month—an event akin to a devastating personal data catastrophe.'
    },
    selection: {
      description: {
        ready: 'Ready to peek into your world?',
        simply: 'Simply select your workspace and voilà',
        begin: '– let the show begin! 🍿'
      },
      invited:
        '{ownerName} invited you to join <span class="text-primary-200 text-nowrap">{workspace}</span>',
      actions: {
        newWorkspace: 'New Workspace'
      },
      userLocked: {
        title: 'Locked Workspace Due to Billing Issues',
        description:
          'Looks like {workspace} by {ownerName} is taking a break due to billing hiccups. Hang tight! Your workspace captain will have everything back on track soon!'
      },
      expiredOwner: {
        title: 'Expired, please check the validity.',
        description:
          "Let's get you back on track—for an uninterrupted monitoring experience, please add a valid payment method to your {workspace} soon.\nThanks a bunch!"
      },
      default:
        '<span class="text-primary-200">{workspace}</span> by <span class="text-primary-200">{ownerName}</span>'
    },
    delete: {
      title: 'We’re sad to see you go ☹️',
      subtitle:
        'We really appreciate the time you spent with us, can you let us know what went wrong?',
      item1: 'I had trouble setting up my cameras.',
      subItem1:
        'As a versatile open platform, we support 3,000+ camera models from top brands, securing compatibility with your existing security investments',
      item2: 'I had trouble with platform’s usability. ',
      subItem2:
        'As a versatile open platform, we support 3,000+ camera models from top brands, securing compatibility with your existing security investments',
      item3: 'I am choosing to go with your competition.',
      item4: "I don't see any added value here.",
      item5: 'I can’t afford the pricing.',
      other: 'Other',
      otherPlaceholder: 'Please type here.',
      noteTitle: 'Data Deletion Notice',
      noteContent:
        'Please be advised, your invaluable data is slated for irreversible deletion within \n' +
        'the next 72 hours. This action is permanent and cannot be undone. To avoid this imminent loss, your immediate intervention is crucial. We strongly urge you to take the necessary steps to preserve your data. Failure to do so will result in the irrevocable loss of your information.',
      permanentlyDelete: 'Permanently Delete',
      talkToSales: 'Talk to Sales',
      talkToSupport: 'Talk to Support',
      FreezeWorkspace: 'Freeze Upcoming Bills',
      permanentlyDeleteTitle: 'Permanent Workspace Deletion ☠️',
      permanentlyDeleteSubTitle: 'To confirm, type in the Workspace name below.',
      permanentlyDeleteContent:
        "By selecting 'Delete', I acknowledge that I am choosing to discontinue Arcadian Orbit Inc.'s services. I understand that this will result in the immediate loss of all my valuable recorded video footage and personal data.",
      deleteWorkspaceInput: 'Type “{data}” *',
      DeleteTitle: 'We hate goodbyes, but we respect your decision',
      DeleteSubTitle:
        'To move forward with the deletion of your workspace, start by making sure all your connected devices are removed. After this is done, just send us a brief message. We assure you of a smooth and hassle-free cancellation of your account.',
      DeleteContent:
        'I acknowledge that by requesting {PermanentDeletion} of my workspace, I am venturing into a digital abyss, halting my subscription and cutting off recurring payments. This grave decision puts me on a precipice, fully aware that failing to retreat will lead to the catastrophic vanishing of all my treasured video footage and personal data within the next month, a loss akin to a personal data apocalypse.',
      PermanentDeletion: "'Permanent Deletion'",
      deleteLastSeatTitle: 'Trouble in Paradise? How About We Pause Your Upcoming Payments?',
      deleteLastSeatContent:
        'Every active workspace has a reserved virtual seat. If you remove your last virtual seat, your account will be permanently deleted. If that’s your final decision, please contact our support team through our website. Alternatively, you can turn off your auto-renewal to freeze all upcoming payments directly from your workspace billing tab until the end of time!'
    },
    operationHours: {
      title: 'Hours of Operation',
      todayData: 'Open today ',
      notSet: 'Not Set',
      closed: 'Closed',
      successUpdate: 'Saved!'
    }
  },

  //log activity
  activityLogs: {
    entity: {
      b: 'Bookmark',
      br: 'Bridge',
      brc: 'Bridge Camera',
      c: 'Camera',
      cg: 'Camera Group',
      cl: 'Clips',
      m: 'Map',
      md: 'Metadata',
      p: 'Payment Details',
      t: 'Team',
      u: 'People',
      w: 'Workspace'
    },
    actions: {
      bookmarkCreate: 'Successfully annotated {date}.',
      bookmarkCreateFailed: 'Unsuccessful attempt to save a moment on {date}.',
      bookmarkUpdate: 'Successfully edited the moment on {date}.',
      bookmarkUpdateFailed: 'Unsuccessful attempt to edit the moment on {date}.',
      bookmarkDelete: 'Deleted a saved moment on {date}',
      bookmarkDeleteFailed: 'Unsuccessful attempt to delete the moment on {date}.',

      bridgeCreate: 'Successfully connected {data}.',
      bridgeCreateFailed: 'Unsuccessful attempt to set up {data}.',
      bridgeUpdate: "Updated {data}'s settings.",
      bridgeUpdateFailed: "Unsuccessful attempt to update {data}'s configurations.",
      bridgeDelete: 'Disconnected {data}.',
      bridgeDeleteFailed: 'Unsuccessful attempt to delete {data} device.',

      cameraGroupCreate: 'Successfully created {cameraGroup}, the camera group.',
      cameraGroupCreateFailed: 'Unsuccessful attempt to create {cameraGroup}, the camera group.',
      cameraGroupUpdate: 'Updated {cameraGroup},the camera group, details.',
      cameraGroupUpdateFailed: 'Unsuccessful attempt to update {cameraGroup}, the camera group.',
      cameraGroupDelete: 'Deleted {cameraGroup}, the camera group and all its dependencies.',
      cameraGroupDeleteFailed: 'Unsuccessful attempt to delete {cameraGroup}, the camera group.',

      // Camera type
      cameraConnect: 'Successfully connected {camera} camera.',
      cameraConnectFailed: 'Unsuccessful attempt to set up {camera} camera.',
      cameraUpdate: "Updated {camera}'s settings.",
      cameraUpdateFailed: "Unsuccessful attempt to update {camera}'s configurations.",
      cameraDelete: 'Deleted {camera} camera and all its stored footage.',
      cameraDeleteFailed: 'Unsuccessful attempt to delete {camera} camera.',
      cameraUpdateConnectionInfo: "Updated {camera}'s connection settings.",
      cameraUpdateConnectionInfoFailed:
        "Unsuccessful attempt to update {camera}'s connection settings.",
      cameraUpdateSchedule: "Updated {camera}'s settings.",
      cameraUpdateScheduleFailed: "Unsuccessful attempt to update {camera}'s settings.",
      cameraUpdateRecordMode: "Updated {camera}'s cloud archiving mode.",
      cameraUpdateRecordModeFailed:
        "Unsuccessful attempt to change {camera}'s cloud archiving mode.",
      cameraAvatarUpdate: "Updated {camera}'s profile avatar.",
      cameraAvatarUpdateFailed: "Unsuccessful attempt to update {camera}'s profile avatar.",
      cameraAvatarDelete: "Deleted {camera}'s profile avatar.",
      cameraAvatarDeleteFailed: "Unable to delete {camera}'s profile avatar.",
      CameraRecordingStartedStatusHealthy:
        'Cloud archiving for {camera} camera is reinstated in a healthy state.',
      CameraRecordingStartedStatusHealthyFailed:
        "Unsuccessful attempt to reinstate {camera}'s cloud archiving to a healthy state.",
      CameraRecordingPausedStatusUnhealthy:
        'Cloud archiving paused as {camera} hardware became unreachable.',
      CameraRecordingPausedStatusUnhealthyFailed: 'Unsuccessful attempt to pause record mode.',
      CameraDisabledPaymentIssue:
        'Unable to record footage for {camera} camera due to payment failure.',
      CameraDisabledPaymentIssueFailed: 'Unsuccessful attempt to pause record mode.',
      CameraEnabledPaymentIssue:
        'Cloud archiving for {camera} camera restored after payment success.',
      CameraEnabledPaymentIssueFailed:
        'Unsuccessful attempt to reinstate cloud archiving to a healthy state.',
      CameraRecordingPausedPaymentIssue: 'Stopped recording due to payment failure.',
      CameraRecordingPausedPaymentIssueFailed: 'Unsuccessful attempt to pause record mode.',
      CameraRecordingStartedPaymentIssue:
        'Cloud archiving for {camera} restored after payment success.',
      CameraRecordingStartedPaymentIssueFailed:
        'Unsuccessful attempt to reinstate cloud archiving to a healthy state.',
      // Clip type
      exportCreate: 'Successfully created a clip from {date}.',
      exportCreateFailed: 'Unsuccessful attempt to create a clip from {date}.',
      exportUpdate: 'Updated a clip from {date}.',
      exportUpdateFailed: 'Unsuccessful attempt to update a clip from {date}.',
      exportDelete: 'Deleted {name} from {date}.',
      exportDeleteFailed: 'Unsuccessful attempt to delete {name} from {date}.',
      exportDownload: 'Downloaded {name} from {date}.',
      exportDownloadFailed: 'Unsuccessful attempt to download {name} from {date}.',
      ExportVideoFailed: 'Unsuccessful attempt to create a clip from {date}.',

      snapshotCreate: 'Captured a snapshot from {date}',
      snapshotCreateFailed: 'Unsuccessful attempt to capture a snapshot from {date}.',
      snapshotUpdate: 'Updated a snapshot from {date}.',
      snapshotUpdateFailed: 'Unsuccessful attempt to update a snapshot from {date}.',
      snapshotDelete: 'Deleted a snapshot from {date}.',
      snapshotDeleteFailed: 'Unsuccessful attempt to delete a snapshot from {date}.',

      stripCreateCustomer: 'Welcomed {user} as a billing customer.',
      stripeCreateCustomerFailed: 'Unable to create {user} as a billing customer.',
      stripUpdateCustomer: "Updated {user}'s customer profile.",
      stripCreateCustomerFailed: 'Unable to create {user} as a billing customer.',
      stripRemoveCustomer: 'Deleted {user} as a customer',
      stripRemoveCustomerFailed: 'Unsuccessful attempt to delete {user} as a customer.',
      stripCreateSubscription: 'Created a {duration} cloud storage subscription, paid {recurring}.',
      stripCreateSubscriptionFailed: 'Unsuccessful attempt to create a subscription.',
      stripUpdateSubscription: 'Updated the {recurring} subscription to {duration} cloud storage.',
      stripUpdateSubscriptionFailed: 'Unsuccessful attempt to update the subscription.',
      stripFreezeSubscription: 'Paused all upcoming payments for the workspace.',
      stripFreezeSubscriptionFailed: 'Unsuccessful attempt to pause all upcoming payments.',
      stripUpdateRenewalSubscription:
        'Renewed subscription to {duration} cloud storage, paid {recurring}',
      stripUpdateRenewalSubscriptionFailed:
        'Unsuccessful attempt to renew the workspace subscription.',
      stripReactivateSubscription:
        'Re-activated workspace subscription of {duration} cloud storage, paid {recurring}.',
      stripReactivateSubscriptionFailed: 'Unsuccessful attempt to re-activate the subscription.',
      stripCreatePaymentMethods: 'Uploaded a payment method ending in ****{card}.',
      stripCreatePaymentMethodsFailed: 'Unsuccessful attempt to upload a payment method.',
      stripDeletePaymentMethods: 'Deleted a payment method ending in ****{card}.',
      stripeDeletePaymentMethods:
        'Unsuccessful attempt to delete a payment method ending in ****{card}.',

      stripUpdateStripeWorkspace: 'stripUpdateStripeWorkspace: Delete this log.', //delete log

      stripeUpdateStripeWorkspaceFailed: 'Unsuccessful attempt to update workspace subscription.',

      stripeTrialEnded: 'Free trial ended on {date}.',

      stripeTrialEndedFailed: 'Unsuccessful attempt to end free trial.',

      SubscriptionCreated: 'New Subscription was created',
      SubscriptionDeleted: 'The Subscription was Deleted',
      SubscriptionPaused: 'The Subscription was Paused',
      SubscriptionResumed: 'The Subscription was Resumed',
      SubscriptionTrialWillEnd: 'The Subscription Trial will End',
      SubscriptionUpdated: 'The Subscription was Updated',

      PaymentIntentCreated: 'PaymentIntentCreated: Delete this log.', //Delete this

      PaymentIntentCreatedFailed:
        'Unsuccessful attempt to schedule a payment collection for invoice #{number}.',
      PaymentMethodDeleted: 'Deleted a payment method ending in ****{card}.',
      PaymentMethodDeletionFailed:
        'Unsuccessful attempt to delete a payment method ending in ****{card}.',
      PaymentMethodAttached: 'Added a new payment method ending in ****{card}.',
      PaymentMethodAttachedFailed:
        'Unsuccessful attempt to add a new payment method ending in ****{card}.',
      PaymentMethodExpiring: 'Expired a payment method ending in ****{card}.',

      // Invoices
      InvoiceCreated: 'Drafted an upcoming invoice #{number} for ${amount} due on {startDate}.',
      InvoiceCreatedFailed:
        'Unsuccessful attempt to draft an invoice for ${amount} due on {startDate}.',
      InvoiceDeleted: 'Deleted invoice #{number} for ${amount} due on {date}.',
      InvoiceDeletedFailed:
        'Unsuccessful attempt to delete invoice #{number} for ${amount} due on {date}.',
      InvoiceFinalized: 'Processed payment for invoice #{number} for ${amount} due on {date}.',
      InvoiceFinalizationFailed:
        'Unsuccessful attempt to process invoice #{number} for ${amount} due on {date}.',
      InvoiceMarkedUncollectible:
        'Failed to process invoice #{number} for ${amount} due on {date}.',
      InvoicePaid: 'Paid invoice #{number} for ${amount} due on {date}.',
      InvoicePaymentActionRequired: '',
      InvoicePaymentFailed:
        'Unsuccessful attempt to pay invoice #{number}for ${amount} due on {date}.',
      InvoicePaymentSucceeded: 'Collected payment for invoice #{number} for ${amount}.',
      InvoiceUpcoming: 'Reminder for an upcoming invoice #{number} for ${amount} due on {endDate}.',

      // User type
      userUpdate: "Updated {user}'s user profile.",
      userUpdateFailed: "Unsuccessful attempt to update {user}'s user profile.",
      userUpdatePassword: "Updated {user}'s password credentials.",
      userUpdatePasswordFailed: "Unsuccessful attempt to update {user}'s password credentials.",
      userUpdatePhone: "Updated {user}'s phone number.",
      userUpdatePhoneFailed: "Unsuccessful attempt to update {user}'s phone number.",
      userUpdateEmail: "Updated {user}'s email.",
      userUpdateEmailFailed: "Unsuccessful attempt to update {user}'s email.",
      userUpdateAvatar: 'Updated profile avatar.',
      userUpdateAvatarFailed: 'Unsuccessful attempt to update profile avatar.',
      userDeleteAvatar: 'Removed user profile avatar.',
      userDeleteAvatarFailed: 'Unsuccessful attempt to delete profile avatar.',
      WorkspaceSecurityDeviceSwitch: 'Logged in via {os}/{browser} - {ip}.',
      WorkspaceSecurityDeviceSwitchFailed:
        'Unsuccessful attempt to log in via {os}/{browser} - {ip}.',

      // Workspace type
      workspaceCreate: '{user} created a new workspace called {workspace}.',
      workspaceCreateFailed: 'Unsuccessful attempt to create a new workspace.',
      workspaceDelete: '{user} deleted the {workspace} workspace.',
      workspaceDeleteFailed: 'Unsuccessful attempt to delete the workspace.',
      workspaceUpdate: "Updated {workspace}'s data.",
      workspaceNameUpdate: "Updated {workspace}'s name ",
      workspaceUpdateFailed: "Unsuccessful attempt to update workspace's data.",
      workspaceInvite: 'Invited {phone} to the {workspace}.',
      workspaceInviteFailed: 'Unsuccessful attempt to invite {email} to the {workspace}.',
      workspaceDeleteUser: 'Deleted {otherUser} from the {workspace}.',
      workspaceDeleteUserFailed: 'Unsuccessful attempt to delete {otherUser} from the workspace.',
      workspaceSuspendUser: 'Suspended {otherUser} from the {workspace}.',
      workspaceSuspendUserFailed: 'Unsuccessful attempt to suspend {user} the {workspace}.',
      workspaceResendUser: 'Resent an invite to {phone} to join the {workspace}.',
      workspaceResendUserFailed: 'Unsuccessful attempt to re-invite {phone} to the {workspace}.',
      workspaceAvatarUpdate: "Updated workspace's profile avatar.",
      workspaceAvatarUpdateFailed: "Unsuccessful attempt to update {workspace}'s profile avatar.",
      workspaceAvatarRemove: "Removed workspace's profile avatar.",
      workspaceAvatarRemoveFailed: "Unsuccessful attempt to delete {workspace}'s profile avatar.",
      workspaceScheduleUpdate: "Updated {workspace}'s hours.",
      workspaceAddressUpdate: "Updated {workspace}'s address.",
      workspaceDescriptionUpdate: "Updated {workspace}'s description.",
      workspaceTagsUpdate: "Updated {workspace}'s Tags.",
      workspaceTagsDelete: "Deleted {workspace}'s Tags.",
      workspaceTagsUpdateFailed: "Updating {workspace}'s Tags Failed.",
      userJoinWorkspace: 'Hooray! {user} joined the {workspace}.',
      userChangePermisson: "Changed {otherUser}'s permission to {team} in the {workspace}.",
      userJoinWorkspaceFailed: 'Unsuccessful attempt to join the {workspace}.',
      userLeaveWorkspace: '{user} left the {workspace}.',
      userLeaveWorkspaceFailed: 'Unsuccessful attempt to leave the {workspace}.',
      singleChange: 'Changed {key} of {workspace} to {value}.',
      emailVerifiedSuccessfully: "Updated {user}'s email"
    },
    title: '{data}: {name} ',
    bookmarkTitle: 'Annotated Moments: {camera}',
    bridgeTitle: 'Bridge: {bridge}',
    clipsTitle: 'Clip Log',
    workspaceTitle: 'Workspace',
    billingTitle: 'Billing & Invoicing',
    eventTitle: 'System Events',
    arcadian: 'ARCADIAN.AI',
    deviceSwitch: 'Device Switch: {os}/{browser} - {ip}',
    filter: {
      workspace: 'All',
      all: 'All',
      me: 'Profile',
      cameras: 'Cameras',
      payment: 'Billing',
      exports: 'Exports',
      snapShots: 'SnapShots',
      bookmarks: 'Bookmarks'
    }
  },

  //bridge
  bridge: {
    connectCamera: 'Connect Cameras',
    arcadianCamera: {
      title: "Fantastique! Let's connect your cameras.",
      subtitle:
        'ArcadianAI is how security monitoring should be: Simple, Affordable & Proactive. Scan you QR code to begin monitoring. ',
      dontHave: 'Don’t have cameras? ',
      infoTitle: 'The Cloud Subscription ',
      infoContent:
        'To fully utilize your AI Security cameras, a monthly or yearly subscription is required. This subscription grants you comprehensive access to all ArcadianAI streaming and monitoring services, unlimited AI heatmap plans, and options for 30, 60, or 90 days of cloud storage. Billing starts as soon as you connect your cameras to the platform.',
      camera: 'ArcadianAI'
    },
    drawer: {
      dontHave: 'Don’t have one? ',
      shop: 'Shop',
      byQRCode: 'QR',
      noCameraDevice: 'No cameras on this device',
      byActivationCode: 'Code',
      uploadImage: 'Upload',
      setupHelp: 'Setup Help',
      infoTitle: 'Why am I seeing this?',
      infoContent:
        "Regular software maintenance updates are vital to ensure your bridge and connected cameras operate smoothly. We've detected that the device in your possession is not compatible with our latest version. Rest assured, the necessary update will only require a few minutes of your time.",
      stayHere: 'Stay here. . . ',
      stayHereContent:
        "We're swiftly connecting your device to the cloud. Please make sure your bridge is powered on and within close proximity. This could take a few minutes.",
      areYouSure: 'Are you sure?',
      areYouSureContent:
        "Auto-discovery of your devices is in progress. Terminate this session by closing the window or clicking 'Cancel', and you'll lose all your work. Want to continue? Select 'Cancel Pairing' again.",
      areYouSureConnectingContent:
        "Connecting of your devices is in progress. Terminate this session by closing the window or clicking 'Cancel', and you'll lose all your work. Want to continue? Select 'Cancel Pairing' again.",
      cameraUsername: 'Camera Username',
      cameraPassword: 'Camera Password',
      discoverMore: 'Discover More',
      connectCameras: 'Connect Cameras',
      skipPassword: 'Skip, Input Passwords for Each Camera',
      updateAlertTitle: 'Why am I seeing this?',
      updateAlertContent:
        "Regular software maintenance updates are vital to ensure your bridge and connected cameras operate smoothly. We've detected that the device in your possession is not compatible with our latest version. Rest assured, the necessary update will only require a few minutes of your time.",
      installUpdate: 'Install Latest Software',
      releaseNote: 'Release Note ( {data} )',
      confirmCancel: 'Cancel Pairing',
      profile: {
        hardwareSpecs: 'Hardware Specs',
        deviceName: 'Device Name',
        deviceLocation: 'Device Location (Physical Address)',
        addTags: 'Add Tags',
        model: 'Model',
        brand: 'Brand',
        hardwareID: 'Hardware ID',
        software: 'Software',
        version: 'Version No.',
        connectedCameras: 'Connected Cameras',
        successUpdate: 'Bridge Updated successfully'
      },
      activation: {
        title: 'Let’s bridge the gap!',
        subtitle:
          'Keep your gear. Simply connect the Arcadian Bridge cloud connector to your modem and we will take care of the rest for you. ',
        addSuccess: 'Bridge Activated Successfully',
        notDetectedAlertTitle: 'Couldn’t locate your bridge',
        notDetectedContent:
          "It seems like your device may have had a momentary lapse. Let's get it back on track! Please ensure that your bridge is nearby and powered on"
      },
      cameras: {
        title: 'Found your bridge!',
        subtitle:
          "We've detected %{data} nearby. To streamline their connection, please share their usernames and passwords with us."
      },
      update: {
        title: 'Found your bridge!',
        subtitle:
          'hey, we’ve made a discovery—your hardware has been detected nearby. To ensure the connection process goes smoothly, it’s essential to update your cloud connector firmware for optimal results.',
        update: {
          requestUpdateSuccess: 'The update will take a while. You will be notified of the result.',
          updateSuccessfully: 'Your Bridge were updated successfully.'
        }
      },
      actions: {
        cancel: 'Cancel',
        connect: 'Connect'
      },
      connecting: {
        connectedSuccessfully: 'Your Bridge was connected successfully'
      }
    },
    status: {
      '0': 'Inactivate',
      '1': 'Connected',
      '2': 'Connecting...',
      '3': 'Disconnected',
      '4': 'Pending Update',
      needUpdate: 'Pending Update',
      installing: 'Installing an Update...'
    },
    menu: {
      editSetup: 'Edit Setup',
      edit: 'Edit',
      view: 'View',
      editBulk: 'Edit Bulk',
      autoDiscover: 'Auto-discover',
      forget: 'Forget Device',
      update: 'Update Available',
      updated: 'Up to date (v.{data})'
    },
    errorMessage: {
      notfound: 'Unrecognized QR Code. Try again or get in touch with our support team.',
      expiredTime: 'Expired Time',
      expiredTimeErrorMessage: "Ops we can't Detective your Bridge, please try again",
      cameraAddSuccess: 'Camera connected successfully',
      cameraConnectFail: 'No cameras added',
      internalservererror: 'Server Error 500',
      dontHaveActiveBridge: "You don't Have an Active bridge. Please go to the previous step",
      'code:isrequired': 'Error: QR code Invalid. Upload a clear image of your QR code.',
      agentactivationfailed: 'Unrecognized Code, Try again or get in touch with our support team.',
      errorDelete: 'Something went wrong...',
      loadDataFailed: 'Something went wrong in loading the data.',
      softwareUpdateFailed: 'The bridge software update failed.',
      softwareUpdateCanceled:
        'The bridge software update cancled, Please try again a few minutes later.'
    },
    messages: {
      successfulDelete: 'Done',
      updateSoftware: 'The bridge software update successfully.'
    },
    discoveryLoader: 'We are doing auto-discovery, please wait ...',
    onboarding: {
      title: 'Ensure your devices are powered on and nearby . 🔌',
      noticeTitle: 'Tips on Plug & Play ',
      noticeContent:
        "Hey! We didn’t spot any cameras, but some might be playing hard to get due to your device's extra security layer. No worries—you can still add them manually via Ports. Need a hand? Give us a shout at {supportNumber} or submit a {supportTicket} now.",
      supportTicket: 'Support Ticket',
      supportNumber: '+1 800 288 9192'
    },
    delete: {
      title:
        'Ready to wipe out {name} and its entire cloud collection (cameras, recordings, clips, and analytics)? Type in the device name to make it official.',
      description:
        "By selecting 'Delete', I acknowledge that I am initiating an irreversible action. This will permanently disconnect Arcadian Orbit Inc.'s services for my device, leading to the immediate and irretrievable loss of all recorded footage and all associated hardware data.",
      deleteButton: 'Delete',
      inputLabel: 'Type \"{name}\" *'
    }
  },

  //security
  security: {
    mySessions: 'My Sessions',
    labels: {
      forgetDevice: 'Forget Device',
      reportProblem: 'Help'
    },
    rules: {
      title: 'Log In Rules',
      password: 'Password',
      sms: 'SMS Verification',
      multiFactor: 'Authenticator App',
      otp: 'SMS Verification',
      tOtp: 'Authenticator App'
    },
    sessions: {
      noSessions: 'No old sessions found.'
    },
    password: {
      serverError: 'Oops! Something went wrong.'
    },
    security: 'Security',
    errors: {
      atLeastOne:
        'hey! You need at least one authentication method enabled to log in. Set up a new one before turning off the old!'
    }
  },

  twoFA: {
    fAAuth: 'Two-Factor Authentication ',
    byApp: 'By Authenticator App',
    byText: 'By Text Message',
    byAppDesc: 'Install any Authenticator App on your mobile device. We recommend: ',
    byTextDesc: 'Receive a one-time code via SMS to ensure your security. ',
    authy: 'Authy',
    authyDes: 'from Authy inc.',
    google: 'Google',
    googleDesc: 'from Google inc.',
    duo: 'Duo Mobile',
    duoDesc: 'from Duo Security ',
    microsoft: 'Microsoft ',
    microsoftDesc: 'from Microsoft Corporation',
    QR: 'Scan the QR Code',
    enterDigit: 'Enter the 6-digit code *',
    sendToPhone: 'Your phone is buzzing with a new SMS from us – go see what the fuss is about!',
    messages: {
      wrongToken: 'Please enter a valid code.'
    }
  },

  //profile
  profile: {
    fullName: 'Full Name',
    timeZone: 'Timezone',
    email: 'Email',
    newEmail: 'New Email',
    enterOtp: 'Enter the 6-digit code generated by your active multi-factor authentication method:',
    activityLogs: 'Activity Log',
    messages: {
      fullNameUpdated: 'Saved!',
      fullNameUpdatedError: 'Oops! Something went wrong.',
      timezoneUpdated: 'Saved!',
      timezoneUpdatedError: 'Oops! Something went wrong.',
      emailVerified: 'Verified!',
      errorVerification: 'Oops! Something went wrong.',
      phoneVerified: 'Verified!'
    },
    newNumber: 'New Number',
    profile: 'Profile'
  },

  dataDeletion: {
    title: 'Data Deletion Protocol',
    main: 'At {site}, we deeply value the trust you place in us with your video surveillance data. Our entire infrastructure is built on the bedrock of preserving the sanctity of your captured moments. Every frame, every pixel, remains confidential and guarded under the watchful eyes of our top-tier security measures on {amazon}. If you decide to request data deletion, please know that we will handle your information with the utmost care and respect, ensuring its prompt removal within 72 hours, while carefully considering any legal or business retention necessities. Contact us directly; we’re here because we care. ',
    privacyPolicy: 'Privacy Policy',
    site: 'ArcadianAI',
    amazon: 'Amazon AWS cloud',
    sendEmail: 'Email CEO',
    DeleteAccount: 'Delete Workspace',
    deleteAlertText:
      'Before we can permanently delete your workspace, please ensure you have disconnected all your connected devices. We appreciate your cooperation.'
  },

  userDelete: {
    weAreSad: 'We’re sad to see you go ☹️',
    whatWentWrong:
      'We really appreciate the time you spent with us, can you let us know what went wrong?',
    troubleSetting: 'I had trouble setting up my cameras.',
    notEmployed: 'I am no longer employed by the company.',
    goCompetition: 'I am choosing to go with your competition. ',
    addedValue: "I don't see any added value in video security monitoring.",
    affordPricing: 'I can’t afford the pricing.',
    deleteNotice: 'Data Deletion Notice',
    deleteDescribe:
      'Please be advised, your invaluable data is slated for irreversible deletion within \n' +
      'the next 72 hours. This action is permanent and cannot be undone. To avoid this imminent loss, your immediate intervention is crucial. We strongly urge you to take the necessary steps to preserve your data. Failure to do so will result in the irrevocable loss of your information.',
    permanentlyDelete: 'Permanently Delete',
    emailCEO: 'Email CEO',
    RequestHelp: 'Request Help',
    disconnectedDevice:
      'Before we can permanently delete your account, \n' +
      'please ensure you have disconnected all your connected devices. We appreciate your cooperation.',
    backMonitoring: 'Back to Monitoring',
    help: 'Help',
    deleteAccount: 'Permanent Account \n' + 'Deletion ☠️',
    writeFullName: 'To confirm, type in your full name below.',
    Marie: 'Type “Marie Roohi”',
    MariePlaceHolder: 'Marie Roohi',
    selectedDeleted:
      "By selecting 'Delete', I acknowledge that I am choosing to discontinue Arcadian Orbit Inc.'s services. I understand that this will result in the immediate loss of all my valuable recorded video footage and personal data.",
    typeHere: 'Please type here.'
  },

  theme: {
    theme: 'Theme',
    dark: 'Dark',
    light: 'Light'
  },

  NewPhoneVerify: {
    phoneNumber: 'Phone Number'
  },

  setImgProfile: {
    header:
      "Your photo will be adjusted to fit a 256 x 256 px size. After this, simply hit 'Save'.",
    edit: 'Edit',
    memberSince: 'Member since ',
    hi: 'hi',
    successUpload: 'Updated!',
    errorUpload: 'Oops! Something went wrong. '
  },

  //heatmap
  heatmap: {
    analysisDuration: 'Analysis Duration',
    timeSlice: 'Time Slices',
    twoHours: '2-hour',
    fourHours: '4-hour',
    eightHours: '8-hour',
    twelveHours: '12-hour',
    aDay: '24-hour',
    aWeek: '7-Day',
    filters: {
      fourHours: 'by 4 hours',
      oneDayHours: 'by 24 hours',
      oneWeekHours: 'by a week'
    },
    tooltip: {
      help: 'Time Slice: Imagine zooming in on a particular segment of your entire data collection period. It segments the long analysis process, which could span 4 hours, 24 hours, or a week, into smaller, easier-to-handle chunks. This allows you to closely observe and understand how the patterns in your data shift during these shorter, specific intervals.',
      analysisDuration:
        'Analysis Duration is the total time frame for which heatmap data is collected and analyzed.',
      timeSlice:
        'Analysis Duration is the total time frame for which heatmap data is collected and analyzed.'
    },
    noHeatMapData:
      'Your AI heatmaps for this camera are brewing!  \n' +
      'Pop back in less than 2 hours for a peek.  ',
    shortWait: 'Short Wait '
  },

  //authentication
  authentication: {
    slider: {
      item1Title: '',
      item1:
        'Breaking News: ArcadianAI Disrupts the Industry with a Self-Evolving AI for the Physical World',
      item2:
        '"I’m built for your world—I see, I learn, and I adapt. I’m sharper than any human, I protect. Hire me."',
      item2NextLine: '— Ranger, Your AI Security Guard',
      item3:
        'Alarming Trend: A Security Incident Strikes Businesses Every Six Seconds. Humans Have Limits—AI Doesn’t.',
      updateTwitter: 'X Updates',
      ad: 'Watch Ranger’s Super Bowl Ad',
      askUs: 'Hire Ranger'
    },
    headers: {
      title: "Hire Ranger—the World's First AI Security Guard.",
      subtitle: 'Your cellphone number is your key to entry! Available in the US 🇺🇸 and Canada 🇨🇦',
      welcomeBack: 'Welcome Back!',
      enterPassword: 'Enter your password. ',
      passwordRecovery: 'Password Recovery'
    },
    signupFooter: {
      main:
        "By clicking '{data}', I am agreeing to Arcadian Orbit Inc.'s {services} " +
        'and {Policy}, and consenting to receive digital communication about my services.',
      TermsofServices: 'Terms of Services',
      PrivacyPolicy: 'Privacy Policy',
      phoneNumber: 'Phone Number',
      signup: 'Sign Up'
    },
    recoveryPassword: {
      passwordUpdate: 'Updated!',
      login: 'Log In',
      PasswordRecovery: 'Password Recovery',
      Password: 'New Password',
      goBackLogin: 'Cancel, Back to Log In',
      Confirm: 'Confirm'
    },
    multiFactorRecovery: {
      multiFactor: 'Multi-Factor',
      authRecovery: 'Authentication Recovery',
      enterDigits: 'Enter your 36-digit recovery code',
      actions: {
        viaSms: 'via new code to { phone }',
        viaOneTime: 'via one-time code to saved devices',
        viaDigits: 'via 36-digits recovery codes',
        cancel: 'Cancel, Back to Log In',
        help: 'Help',
        sendAgain: 'Send Again',
        recoverMFA: 'Recover MFA'
      },
      messages: {
        otpLabel: 'Enter the 6-digit code sent to your phone',
        otpError: 'Oops. Something went wrong.',
        passwordError: 'Oops. Something went wrong.',
        recovered: 'Authentication is recovered.',
        digitsTooltip:
          'Your unique recovery code, downloaded \n' +
          'during MFA activation, is vital for account recovery.',
        confirmIdentify:
          '    Confirm your identity by checking for a notification from XXXX Mobile on your trusted devices. Please be aware\n' +
          '          that this notification will only remain valid for 5 minutes.'
      },
      labels: {
        yourPassword: 'Your Password',
        recoveryCode: '36-digit recovery code'
      }
    },
    safeModeLabel: 'Keep me logged in for easy access.',
    socialLoginsDivider: 'OR continue with',
    OTPEnterCode: 'Enter the 6-digit code sent to your phone',
    TOTPEnterCode: 'Enter the 6-digit code sent to your {app}',
    authenticatorApp: 'Authenticator App',
    backToNumber: 'I have a new number',
    recoveryTotp: 'Recover Authentication',
    passwordForget: 'I forgot my password',
    newUserHeader: 'Welcome!',
    newUserDescription: 'One last step to sign you up.',
    countCamera: {
      title: ['Own', 'Cameras? *'],
      none: 'None',
      personal: '1 - 5',
      business: '5 - 10',
      enterprise: '10 - ∞'
    },
    cameraBrand: {
      title: 'Which brands?',
      subTitle: 'Select all that apply.',
      Arcadian: 'Arcadian',
      Uniview: 'Uniview',
      Hanwha: 'Hanwha',
      VivoTek: 'VivoTek',
      Sunell: 'Sunell',
      Axis: 'Axis',
      Bosch: 'Bosch',
      HikVision: 'HikVision',
      Honeywell: 'Honeywell',
      Dahua: 'Dahua',
      Other: 'Other'
    },
    sidebar: {
      title: 'Identity Verification',
      passwordInstead: 'Enter Password Instead',
      mfaInstead: 'Use Multi-Factor Auth. Instead',
      smsDescription:
        'To ensure your account stays secure, some actions need extra verification. Please enter the 6-digit code sent to your phone.',
      appDescription:
        'To ensure your account stays secure, some actions need extra verification. Please open your multi-factor Authenticator App and enter the generated code in the field below.',
      passwordDescription:
        'To ensure your account stays secure, some actions need extra verification. Please enter the 6-digit code sent to your phone.',
      alert: {
        title: 'Fun Fact',
        description:
          "You're stepping into the shoes of a SuperUser! This is what we call 'Admin' or 'Sudo' mode. It's like having a backstage pass at a rock concert – you get to see and do all the cool things most people can't! You can install new features, tweak some settings, and more. But remember, with your backstage pass comes responsibility, so be careful with your new found powers!"
      },
      actions: {
        recoverMFA: 'Recover Authentication',
        recoverPassword: 'Recover Password'
      }
    }
  },

  // Mobile
  // Elimination
  mobile: {
    elimination: {
      title: 'AI in Your Pocket: Download Now!',
      description:
        'ArcadianAI is best experienced on a desktop or through our mobile apps for iOS and Android.',
      download: {
        title: 'Download on',
        titleGet: 'Get it on'
      },
      link: {
        prefix: 'Questions?',
        text: 'Contact Us.'
      }
    }
  },

  capacity: {
    title: 'Melting Servers 🥵',
    subTitle:
      'We’re expanding our infrastructure for more sign ups. {boldText} Leave your information to be the first to know.',
    boldText: 'We’ll be back up shortly.',
    email: 'Email *',
    btn: 'submit',
    successAlert: 'Thank you! You’ll be the first to know.'
  },

  authLabels: {
    email: 'Your Email',
    fullname: 'Your Fullname',
    internetProvider: 'Your Internet Provider',
    password: 'Set a Password'
  },

  //ai
  ai: {
    moreThan50: 'More than 50% match',
    moreThan70: 'More than 70% match',
    moreThan90: 'More than 90% match',
    showEveryThing: 'Show everything',
    placeHolderAISearch: 'What are you looking for today?',
    search: 'Search',
    back: 'Back',
    orbit: {
      expiredTextMessage: 'The chat has expired. Please start a new conversation.',
      errorTextMessage: 'Search timed out. Tap here to try again',
      tapHere: 'tap here',
      toTryAgain: 'to try again'
    }
  }
}
