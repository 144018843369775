import { defineStore } from 'pinia'
import { useServices } from '@/lib/services'
import { useWorkspaceStore } from '@/modules/Workspace/store/useWorkspaceStore'
import { removedItem } from '@/stores/StoreUtils'
import { SubscriptionStatus } from '@/lib/api'
import { getSubscriptionList } from './utils/paymentDataConvertors'
import { usePermissionManager } from '@/modules/Permissions'
import { CreateCameraLimit } from './payment.interface'

interface PaymentStoreState {
  activeSubscription: any
  paymentMethods: any[]
  products: any[]
  prices: any[]
  invoices: any[]
  stripeWorkspace: any
  workspaceSubscription: any[]
  camerasCount: number
  showPmDrawer: boolean
  invoiceUpcoming: any
  loading: boolean
  upcomingModal: boolean
}

export enum PaymentStatus {
  NoPaymentMethodForAccess,
  HasCameraNoAccess,
  ChangePaymentMethodForAccess,
  CompleteAccess,
  Trial
}

function sleep(t: number) {
  return new Promise((resolve) => setTimeout(resolve, t))
}

export const usePaymentStore = defineStore('payment', {
  state: (): PaymentStoreState => ({
    activeSubscription: null,
    stripeWorkspace: null,
    paymentMethods: [],
    prices: [],
    products: [],
    invoices: [],
    workspaceSubscription: [],
    camerasCount: 0,
    showPmDrawer: false,
    invoiceUpcoming: undefined,
    loading: true,
    upcomingModal: false
  }),
  getters: {
    promotionId(state) {
      if (state.activeSubscription) {
        return state.activeSubscription.data?.discount?.promotion_code
      }
      return null
    },
    activeSubscriptionStatus(): SubscriptionStatus | null {
      return this.activeSubscription ? this.activeSubscription.status : null
    },
    paymentStatus(): PaymentStatus {
      const hasCameras = this.camerasCount > 0
      const hasPaymentMethods = this.paymentMethods.length > 0
      const hasActiveSubscription = !!this.activeSubscription

      if (!hasPaymentMethods && !hasActiveSubscription)
        return PaymentStatus.NoPaymentMethodForAccess

      if (hasCameras && hasPaymentMethods && !hasActiveSubscription)
        return PaymentStatus.HasCameraNoAccess

      if (hasPaymentMethods && !hasActiveSubscription)
        return PaymentStatus.ChangePaymentMethodForAccess

      if (
        hasPaymentMethods &&
        hasActiveSubscription &&
        this.activeSubscription.status !== 'trialing'
      )
        return PaymentStatus.CompleteAccess

      return PaymentStatus.Trial
    },
    defaultPrice(): any {
      if (this.prices.length > 0) {
        const data = this.prices.find((item) => item.data.metadata.default === 'true')
        return data ? data : null
      } else return null
    },
    defaultCurrency(): any {
      return this.prices[0]?.defaultCurrency ? this.prices[0]?.defaultCurrency : null
    },
    stripeAddress(): any {
      return this.stripeWorkspace?.data?.address
    },
    subscriptionList(state) {
      return getSubscriptionList(state.prices)
    },
    lastCancelSubscription(state) {
      return state.workspaceSubscription.find(
        (item) => item.status === 'canceled' || item.status === 'past_due'
      )
    },
    activeSubscriptionRecurring(state) {
      if (state.activeSubscription) {
        return state.activeSubscription.data.items.data[0].price.recurring.interval
      } else {
        return ''
      }
    },
    lastCanceledPrice(state) {
      const lastCancelSubscription = state.workspaceSubscription.find(
        (item) => item.status === 'canceled' || item.status === 'past_due'
      )
      return state.prices.find((item) => item.stripeRef === lastCancelSubscription?.data?.plan?.id)
    },
    cloudStorageList(state) {
      return (recurring: string) => {
        if (state.products.length > 0) {
          const pricesFilterByRecurring = state.products
            .map((product) =>
              product.prices.find(
                (item) => String(item.data.recurring.interval) === String(recurring)
              )
            )
            .filter((product) => !!product && !!product.data.nickname)
          return pricesFilterByRecurring.map((item) => {
            return {
              label: item?.data.nickname,
              action: {
                id: item?.id,
                stripeRef: item?.stripeRef,
                nickname: item?.data.nickname,
                unitAmount: item?.data.unit_amount,
                recurring: item?.data.recurring.interval,
                intervalCount: item?.data.recurring.interval_count,
                active: item?.data.active
              }
            }
          })
        } else return []
      }
    },
    activeSubscriptionAutoRenewal(state) {
      return state.activeSubscription?.autoRenewal || false
    },
    activePaymentMethod(state) {
      return state.stripeWorkspace?.data?.invoice_settings?.default_payment_method
    },
    hasPaymentMethod(state) {
      return state.paymentMethods.length > 0
    }
  },
  actions: {
    openUpcommingModal() {
      this.upcomingModal = true
    },
    closeUpcommingModal() {
      this.upcomingModal = false
    },
    openPMDrawer() {
      if (!document.getElementById('payment-element')) this.showPmDrawer = true
    },
    closePMDrawer() {
      this.showPmDrawer = false
    },

    async loadProduct(force: boolean = false) {
      try {
        if (this.products.length === 0 || force) {
          this.prices = await useServices().stripePayment.price.allPriceDetails()
          const data = await useServices().stripePayment.product.findAllProductDetails()
          this.products = data.filter((item) => item.prices)
          this.products.forEach((product) => {
            const priceData: any[] = []
            product.prices.forEach((price) => {
              if (price) {
                const findItem = this.prices.find((item) => price.stripeRef === item.stripeRef)
                if (findItem && findItem.data) priceData.push(findItem)
              }
            })
            product.prices = priceData
          })
          return this.products
        }
      } catch (e) {
        console.log(e)
      }
    },
    async loadCurrentStripeWorkspace(force: boolean = false) {
      const workspaceStore = useWorkspaceStore()
      if (
        workspaceStore.currentWorkspace &&
        workspaceStore.currentWorkspace.id &&
        (!this.stripeWorkspace || force)
      ) {
        this.stripeWorkspace = await useServices().stripePayment.workspace.findStripeWorkspace(
          String(workspaceStore.currentWorkspace.id)
        )
      }
      return this.stripeWorkspace
    },
    async updateStripeWorkspace(data: any) {
      const workspaceStore = useWorkspaceStore()
      if (workspaceStore.currentWorkspace && workspaceStore.currentWorkspace.id) {
        this.stripeWorkspace = await useServices().stripePayment.workspace.updateStripeWorkspace(
          String(workspaceStore.currentWorkspace.id),
          data
        )
      }
      return this.stripeWorkspace
    },
    async loadActiveSubscription(force: boolean = false) {
      const workspaceStore = useWorkspaceStore()
      if (
        workspaceStore.currentWorkspace &&
        workspaceStore.currentWorkspace.id &&
        (this.activeSubscription === null || force)
      ) {
        // findActiveSubscriptionByWorkspaceDetailed returns if user has no active subscription,
        // Next line will block the parallel getting active subscription
        if (this.activeSubscription === null) this.activeSubscription = ''
        const data =
          await useServices().stripePayment.subscription.findActiveSubscriptionByWorkspaceDetailed(
            String(workspaceStore.currentWorkspace?.id)
          )
        this.activeSubscription = data
      }
      return this.activeSubscription
    },
    async loadPaymentMethods(force: boolean = false) {
      const workspaceStore = useWorkspaceStore()
      if (workspaceStore.currentWorkspace && (this.paymentMethods.length === 0 || force)) {
        this.paymentMethods =
          await useServices().stripePayment.workspace.getWorkspacePaymentMethods(
            String(workspaceStore.currentWorkspace.id)
          )
      }
      return this.paymentMethods
    },

    async createPaymentMethods(PaymentId) {
      const workspaceStore = useWorkspaceStore()
      if (workspaceStore.currentWorkspace) {
        const newPaymentMethod =
          await useServices().stripePayment.workspace.createWorkspacePaymentMethods(
            String(workspaceStore.currentWorkspace.id),
            {
              token: PaymentId
            }
          )
        await this.loadCurrentStripeWorkspace(true)
        return newPaymentMethod
      }
    },
    async updatePaymentMethods(card: any) {
      //  TODO: Implement updating
    },
    async removePaymentMethods(card: any) {
      const workspaceStore = useWorkspaceStore()
      if (workspaceStore.currentWorkspace) {
        await useServices().stripePayment.workspace.removeWorkspacePaymentMethods(
          String(workspaceStore.currentWorkspace.id),
          String(card.id)
        )
        removedItem(this.paymentMethods, card)
      }
    },
    async createTrialSubscription(workspaceId?: string) {
      const prices = await useServices().stripePayment.price.allPriceDetails()
      const workspaceStore = useWorkspaceStore()
      if (prices.length > 0 && workspaceStore.currentWorkspace) {
        const plane = prices.find((item) => item.data.metadata.default === 'true')
        if (plane) {
          try {
            await useServices().stripePayment.subscription.createSubscription({
              items: [{ id: plane.id, quantity: 1 }],
              workspaceId: workspaceId || String(workspaceStore.currentWorkspace.id)
            })
          } finally {
            await this.fetchPaymentInformation(true)
          }
        }
      }
    },

    async loadActiveSubscriptionWithOutDetail() {
      const workspaceStore = useWorkspaceStore()
      if (workspaceStore.currentWorkspace && workspaceStore.currentWorkspace.id) {
        this.activeSubscription =
          await useServices().stripePayment.subscription.findActiveSubscriptionByWorkspace(
            String(workspaceStore.currentWorkspace.id)
          )
      }
      return this.activeSubscription
    },

    async freeSeats(): Promise<number> {
      const workspaceStore = useWorkspaceStore()
      if (workspaceStore.currentWorkspace) {
        const data = await useServices().stripePayment.quota.findAdvanceQuota({
          workspaceId: workspaceStore.currentWorkspace.id as string,
          type: 'camera'
        })
        if (data.length > 0) {
          const maxCount = data[0].maxCount
          const usedCount = data[0].usedCount
          return maxCount - usedCount
        } else return -1
      } else {
        return 0
      }
    },

    async canCreateNewCamera(newCameraCount: number): Promise<CreateCameraLimit> {
      const free = await this.freeSeats()
      if (free === -1) return CreateCameraLimit.notFound
      if (free >= newCameraCount) return CreateCameraLimit.true
      else return CreateCameraLimit.false
    },

    async upgradeSubscription(cameraCount: number) {
      const priceId = this.activeSubscription.data.items.data[0].price.id
      const prices = await useServices().stripePayment.price.findAllPrice()
      const price = prices.find((item) => item.stripeRef === priceId)
      const workspaceStore = useWorkspaceStore()
      if (price && workspaceStore.currentWorkspace) {
        const data = await useServices()
          .stripePayment.quota.findAdvanceQuota({
            workspaceId: workspaceStore.currentWorkspace.id as string,
            type: 'camera'
          })
          .then((r) => r[0])
        if (data) {
          const maxCount = data.maxCount
          const usedCount = data.usedCount
          const newCameraCount = usedCount + cameraCount - maxCount
          if (newCameraCount > 0) {
            await useServices().stripePayment.subscription.updateSubscription(
              this.activeSubscription.id,
              {
                items: [
                  {
                    id: price.id,
                    quantity: maxCount + newCameraCount
                  }
                ]
              }
            )
            await Promise.all([
              this.loadActiveSubscription(),
              this.loadCurrentStripeWorkspace(true)
            ])
            let result = await useServices()
              .stripePayment.quota.findAdvanceQuota({
                workspaceId: workspaceStore.currentWorkspace.id as string,
                type: 'camera'
              })
              .then((r) => r[0])
            while (result.maxCount !== maxCount + newCameraCount) {
              await sleep(1000)
              result = await useServices()
                .stripePayment.quota.findAdvanceQuota({
                  workspaceId: workspaceStore.currentWorkspace.id as string,
                  type: 'camera'
                })
                .then((r) => r[0])
            }
          }
        } else throw new Error('NoSubscriptionFound')
      }
    },

    async downgradeSubscription() {
      if (this.activeSubscription.data.quantity > 1) {
        const priceId = this.activeSubscription.data.items.data[0].price.id
        const prices = await useServices().stripePayment.price.findAllPrice()
        const price = prices.find((item) => item.stripeRef === priceId)
        if (price) {
          await useServices().stripePayment.subscription.updateSubscription(
            this.activeSubscription.id,
            {
              items: [
                {
                  id: price.id,
                  quantity: this.activeSubscription.data.quantity - 1
                }
              ]
            }
          )
          await Promise.all([
            this.loadActiveSubscription(true),
            this.loadCurrentStripeWorkspace(true)
          ])
        }
      }
    },

    async getWorkspaceSubscriptions(force: boolean = false) {
      const workspaceStore = useWorkspaceStore()
      if (this.workspaceSubscription.length === 0 || force) {
        if (workspaceStore.currentWorkspace && workspaceStore.currentWorkspace.id) {
          const workspaceSubscription =
            await useServices().stripePayment.subscription.findSubscriptionByWorkspaceDetail(
              workspaceStore.currentWorkspace.id
            )

          const workspaceSubscriptionEnded = workspaceSubscription
            .filter((p) => p.data !== undefined && p.data.ended_at !== undefined)
            .sort((a, b) => b.data.ended_at - a.data.ended_at)

          const workspaceSubscriptionNotEnded = workspaceSubscription.filter(
            (p) => p.data !== undefined && p.data.ended_at === undefined
          )

          this.workspaceSubscription = workspaceSubscriptionNotEnded.concat(
            workspaceSubscriptionEnded
          )
        }
      }
    },

    async getInvoiceUpcoming() {
      try {
        this.invoiceUpcoming = await useServices().stripePayment.invoice.invoiceUpcoming()
      } catch (e: any) {
        if (e.data.statusCode && e.data.statusCode === 404) {
          this.invoiceUpcoming = undefined
        }
      }
    },
    async updateAutoRenewal(data: boolean) {
      await useServices().stripePayment.subscription.updateRenewalSubscription(
        this.activeSubscription.id,
        { enabled: data }
      )
      await this.loadActiveSubscription(true)
    },

    async fetchPaymentInformation(force: boolean = false) {
      this.startLoading()
      const workspaceStore = useWorkspaceStore()
      const permissionManager = usePermissionManager()
      if (
        permissionManager.hasPermission({
          action: 'workspaceManagePayment'
        }) ||
        permissionManager.hasPermission({
          action: 'workspaceViewPayment'
        })
      ) {
        await Promise.all([
          this.loadActiveSubscription(force),
          this.loadPaymentMethods(force),
          this.getWorkspaceSubscriptions(force),
          this.loadCurrentStripeWorkspace(force),
          this.loadProduct(force)
        ])
      } else {
        this.reset()
      }
      this.stopLoading()
    },

    startLoading() {
      this.loading = true
    },

    stopLoading() {
      this.loading = false
    },

    reset() {
      this.activeSubscription = null
      this.stripeWorkspace = null
      this.paymentMethods = []
      this.prices = []
      this.products = []
      this.invoices = []
      this.workspaceSubscription = []
      this.camerasCount = 0
      this.showPmDrawer = false
      this.invoiceUpcoming = undefined
      this.loading = true
      this.upcomingModal = false
    }
  }
})
