import { useServices } from '@/lib/services'
import {
  type CreateCustomerSettingData,
  type CreateCustomerSettingResponse,
  CustomSettingsType
} from '@/lib/api'
import {
  Grids,
  LayoutType,
  type LayoutBoxConfig,
  type LayoutConfig,
  type LayoutSettings
} from '@/player/interfaces'
import { useCameraStoreWrapper } from './cameraStoreWrapper'
import { computed } from 'vue'

export function useLayoutStoreWrapper() {
  const service = useServices().customSetting
  const keyIdMap: Map<string, string> = new Map()

  const defaultLayout = {
    type: LayoutType.FREE,
    key: Grids.OneXTen,
    columns: Array(10).fill(1),
    rows: [1]
  }

  function updateKeyIdMap(response: CreateCustomerSettingResponse) {
    keyIdMap.set(response.key, response.id)
    return Array.isArray(response.value) ? response.value : response.value.data
  }

  function createId(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let randomString = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      randomString += characters[randomIndex]
    }
    return randomString
  }

  function apiSerializer(id: string, settings: LayoutSettings) {
    return {
      key: `layout:${id}`,
      type: CustomSettingsType.userWorkspace,
      value: {
        data: settings.viewSetup,
        layout: settings.layout,
        createdAt: Date.now()
      }
    }
  }

  function apiDeserializer(res: CreateCustomerSettingData): {
    id: string
    viewSetup: Array<LayoutBoxConfig>
    layout: LayoutConfig
  } {
    return {
      id: res.key.replace('layout:', ''),
      viewSetup: res.value.data,
      layout: res.value.layout
    }
  }

  async function create(id: string, settings: Partial<LayoutSettings>): Promise<LayoutBoxConfig[]> {
    const { viewSetup = [], layout = defaultLayout } = settings
    return service.create(apiSerializer(id, { viewSetup, layout })).then(updateKeyIdMap)
  }

  function removeExtraSettings(data: CreateCustomerSettingResponse[]) {
    if (data.length > 1) {
      for (let i = 1; i < data.length; i++) {
        service.remove(data[i].id)
      }
    }
  }

  async function load(id: string): Promise<LayoutSettings> {
    return service
      .find({
        key: `layout:${id}`,
        type: CustomSettingsType.userWorkspace
      })
      .then((res) => {
        res.sort((a, b) => (a.value?.createdAt - b.value?.createdAt < 0 ? 1 : -1))
        if (res.length > 1) {
          removeExtraSettings(res)
        }
        if (res[0]) {
          return {
            viewSetup: updateKeyIdMap(res[0]) as LayoutBoxConfig[],
            layout: res[0].value.layout as LayoutConfig
          }
        } else return { viewSetup: [], layout: defaultLayout }
      })
  }

  async function getLayoutServiceId(id: string): Promise<string> {
    if (!keyIdMap.has(`layout:${id}`)) {
      await load(id)
    }
    const csId = keyIdMap.get(`layout:${id}`) || ''
    return csId
  }

  async function update(id: string, settings: Partial<LayoutSettings>): Promise<LayoutSettings> {
    const { viewSetup = [], layout = defaultLayout } = settings
    const csId = await getLayoutServiceId(id)
    if (!useCameraStoreWrapper().loading.value) {
      return updatePromise(csId, id, { viewSetup, layout })
    }
    return { viewSetup, layout }
  }

  async function updatePromise(
    csId: string,
    id: string,
    settings: Partial<LayoutSettings>
  ): Promise<LayoutSettings> {
    const { viewSetup = [], layout = defaultLayout } = settings
    const response = apiDeserializer(
      await service.update(csId, apiSerializer(id, { viewSetup, layout }))
    )
    return response
  }

  async function remove(id: string) {
    const csId = await getLayoutServiceId(id)
    await service.remove(csId)
  }

  return {
    createId,
    create,
    load,
    update,
    remove,
    defaultLayout
  }
}
