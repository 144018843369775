// version 0.0.0
import { defineStore, storeToRefs } from 'pinia'
import { useHelpers, useServices } from '@/lib/services'
import type {
  WorkSpaceCurrent as WSC,
  UserWorkSpaceData,
  CreateWorkspace,
  operationHours as OperationHours,
  Address
} from '@/lib/api'
import { Languages, WorkspaceState, type WorkspaceStatus } from '@/lib/api'
import { useAuthenticationStore } from '@/stores/authentication/useAuthenticationStore'
import { createDefaultWorkspaces } from '@/modules/Workspace/helpers/CreateWorkspace'
import { useWorkspaceStatusStore } from './useWorkspaceStatusStore'
import { useEventBus } from '@/utils/event-bus/EventBus'
import { ConvertError } from '@/utils/helpers'
import { computed, reactive, ref } from 'vue'

export function workspaceStatusToNumber(status: WorkspaceState) {
  switch (status) {
    case WorkspaceState.pending:
      return 1
    default:
      return 2
  }
}

const locationDefault: Address = {
  longitude: -74.0072955,
  latitude: 40.7094756,
  line1: '',
  isDirect: true,
  state: '',
  country: '',
  postalCode: ''
}

export const useWorkspaceStore = defineStore('WorkspaceSetting', () => {
  const _eventBus = useEventBus()
  const _helpers = useHelpers()
  const _workspaceManager = useServices().workspaceManager.workspace
  const _userManager = useServices().workspaceManager.user
  const _addressManager = useServices().workspaceManager.address
  const lockState = ref(true)
  const _currentWorkspace = reactive<WSC | { needInitiate: true }>({ needInitiate: true })
  const workspaces = reactive<Array<UserWorkSpaceData>>([])
  const resource = reactive({})
  const currentWorkspaceAvatar = ref('')
  const location = reactive({ ...locationDefault })
  const disableOperationsUpdate = ref(true)
  const operationsString = ref('')
  const _openOperations = ref(false)

  const currentWorkspace = computed<WSC | undefined>(() => {
    if ('needInitiate' in _currentWorkspace) {
      return undefined
    } else {
      return _currentWorkspace
    }
  })

  const openOperations = computed(() => _openOperations.value)

  const workspacesList = computed(() => {
    const workspaceStatus: WorkspaceStatus[] = useWorkspaceStatusStore().statusList
    const workspacesTemp: Array<[UserWorkSpaceData, WorkspaceStatus | undefined]> = [
      ...workspaces
    ].map((w) => [w, workspaceStatus.find((ww) => ww.id === w.id)])
    workspacesTemp.sort(([aW, aS], [bW, bS]) => {
      if (aS && bS) {
        return (
          Number(aS.lockForUser) - Number(bS.lockForUser) ||
          Number(bS.activePayment) - Number(aS.activePayment) ||
          workspaceStatusToNumber(aW.status) - workspaceStatusToNumber(bW.status) ||
          new Date(bW.createdAt).getTime() - new Date(aW.createdAt).getTime()
        )
      } else return 0
    })
    return workspacesTemp.map(([w]) => w)
  })

  function triggerOperations(state: boolean) {
    _openOperations.value = state
  }

  function setWorkspace(workspace: WSC) {
    Object.assign(_currentWorkspace, workspace)
    delete _currentWorkspace['needInitiate']
    operationsString.value = JSON.stringify(
      workspace.operationHours.sort((a, b) => a.dayName - b.dayName)
    )
    disableOperationsUpdate.value = true
  }

  async function updateCurrentWorkspace(data: CreateWorkspace) {
    if (currentWorkspace.value) {
      const workspace = await _workspaceManager.update(String(currentWorkspace.value.id), data)
      setWorkspace(workspace)
      return workspace
    }
    return currentWorkspace
  }

  async function createNewWorkspace() {
    await fetchWorkspaces(true)
    const userWorkspaceCount: number = workspaces.length
    const workspace = await _workspaceManager.create({
      name: 'My Space ' + _helpers.workspaceHelper.integerToRoman(userWorkspaceCount),
      description: "Connect Any Device, Secure Any Space. Because It's Powerful to Take Control.",
      language: Languages.english,
      welcomeMessage: 'welcome'
    })
    await _userManager.switchWorkspace(String(workspace.id))
    setWorkspace(workspace)
    useAuthenticationStore().storage.setItem('workspaceId', String(workspace.id))
    return workspace
  }

  async function fetchCurrentWorkspace() {
    const service = useServices()
    const workspace = await service.workspaceManager.workspace.current()
    setWorkspace(workspace)
  }

  async function fetchWorkspaces(force: boolean = false, avatarGet: boolean = true) {
    try {
      if (workspaces.length === 0 || force) {
        const workspacesTemp = await _userManager.workspaces()
        await useWorkspaceStatusStore().fetchStatuses()
        const { currentUser } = storeToRefs(useAuthenticationStore())
        if (!currentUser.value) await useAuthenticationStore().getCurrentUser()
        if (avatarGet) {
          Object.assign(workspaces, await getAvatarWs(workspacesTemp))
        } else {
          Object.assign(workspaces, workspacesTemp)
        }
        await useWorkspaceStatusStore().fetchStatuses()
        if (workspaces.length === 0) {
          await createDefaultWorkspaces()
        }
      }
      return workspaces
    } catch (e) {
      _eventBus.emit('toast.add', {
        severity: 'error',
        summary: 'Workspaces statuses',
        detail: ConvertError(e),
        life: 3000
      })
    }
  }

  async function getWorkspaceResource() {
    if (currentWorkspace.value) {
      try {
        const response = await useServices().permissionManager.resource.findResource(
          currentWorkspace.value.id.toString(),
          'w'
        )
        Object.assign(resource, response)
      } catch (e) {
        console.log(e)
      }
    }
  }
  async function loadCurrentWorkspaceAvatar(force: boolean = false): Promise<void> {
    if (currentWorkspace.value && (currentWorkspaceAvatar.value === '' || force)) {
      // eslint-disable-next-line no-async-promise-executor
      const image = await _workspaceManager.avatarGet(String(currentWorkspace.value.id))
      try {
        if (image.data.size > 0) {
          const reader = new FileReader()
          reader.addEventListener(
            'load',
            () => {
              setCurrentWorkspaceAvatar(reader.result as string)
            },
            false
          )
          reader.readAsDataURL(image.data)
        }
      } catch (e) {
        //
      }
    }
  }
  async function getAvatarWs(workspaces) {
    const promises = workspaces.map(async (eachWs) => {
      if (currentWorkspace.value && eachWs.id === currentWorkspace.value.id) {
        return { ...eachWs, logo: currentWorkspaceAvatar.value }
      }
      const logo = await _helpers.workspaceHelper.getWorkspaceAvatar(String(eachWs?.id))
      if (logo) {
        if (currentWorkspace.value && eachWs.id === currentWorkspace.value.id) {
          setCurrentWorkspaceAvatar(logo as string)
        }
        return { ...eachWs, logo: logo }
      } else {
        return eachWs
      }
    })
    return Promise.all(promises)
  }

  async function updateAvatar(image: FormData) {
    await _workspaceManager.avatarUpdate(image)
    await loadCurrentWorkspaceAvatar(true)
  }

  function updateCurrentOperationHours(newOperationHours: Array<OperationHours>) {
    if (currentWorkspace.value) {
      const newListString = JSON.stringify(newOperationHours.sort((a, b) => a.dayName - b.dayName))
      const oldListString = operationsString.value
      if (newListString !== oldListString) {
        disableOperationsUpdate.value = false
      } else {
        disableOperationsUpdate.value = true
      }
      _currentWorkspace['operationHours'] = newOperationHours // IT'S SAFE
    }
  }

  function setCurrentWorkspaceAvatar(value: string) {
    currentWorkspaceAvatar.value = value
  }

  async function removeCurrentWorkspace() {
    await _workspaceManager.removeCurrentWorkspace()
    if (currentWorkspace.value) {
      const id = currentWorkspace.value.id
      const index = workspaces.findIndex((workspace) => workspace.id === id)
      workspaces.splice(index, 1)
    }
  }

  async function removeAvatar() {
    await _workspaceManager.avatarRemove()
    setCurrentWorkspaceAvatar('')
  }
  async function loadWorkspaceAddress() {
    await useServices()
      .workspaceManager.address.find()
      .then((address) => {
        if (address) {
          Object.assign(location, address)
        }
      })
  }
  async function fetchWorkspaceAddress(force: boolean = false) {
    if (location.line1 === '' || force) {
      Object.assign(location, await _addressManager.find())
    }
  }
  async function updateWorkspaceAddress(address: Address) {
    Object.assign(location, await _addressManager.update(address))
  }
  async function deleteWorkspaceAddress() {
    await useServices()
      .workspaceManager.address.remove()
      .then(() => {
        Object.assign(location, locationDefault)
      })
  }

  function reset() {
    lockState.value = true
    _openOperations.value = false
    Object.assign(_currentWorkspace, { needInitiate: true })
    workspaces.length = 0
    Object.assign(resource, {})
    currentWorkspaceAvatar.value = ''
    Object.assign(location, { ...locationDefault })
    disableOperationsUpdate.value = true
    operationsString.value = ''
  }

  return {
    lockState,
    currentWorkspace,
    location,
    workspacesList,
    workspaces,
    currentWorkspaceAvatar,
    disableOperationsUpdate,
    operationsString,
    openOperations,
    updateCurrentWorkspace,
    createNewWorkspace,
    fetchCurrentWorkspace,
    fetchWorkspaces,
    getWorkspaceResource,
    loadCurrentWorkspaceAvatar,
    updateAvatar,
    getAvatarWs,
    updateCurrentOperationHours,
    setCurrentWorkspaceAvatar,
    removeCurrentWorkspace,
    removeAvatar,
    loadWorkspaceAddress,
    fetchWorkspaceAddress,
    updateWorkspaceAddress,
    deleteWorkspaceAddress,
    triggerOperations,
    reset,
    _currentWorkspace
  }
})
