import { AnalyticEventTypes } from '@/lib/api'

export enum AlertTypes {
  inApp = 'in-app',
  sms = 'sms',
  email = 'email',
  firebase = 'firebase'
}

export enum ScheduleMode {
  afterHours,
  inHours,
  all
}

export interface ScheduleUserEntity {
  id: string
  isActive: boolean
  teamId?: string
  isLock?: boolean
}

export interface ScheduleFilterEntity {
  eventType: AnalyticEventTypes
  eventTypeNum: number
  indexes?: string[] // -1 for events without index
}

export interface ScheduleUserDto {
  id: string

  isActive?: boolean
}

export interface ScheduleTimeDto {
  hour: number

  minute: number

  second: number
}

export interface SchedulePeriodDto {
  startTime: ScheduleTimeDto

  endTime: ScheduleTimeDto
}

export interface ScheduleRoutineDto {
  periods: Array<SchedulePeriodDto>

  date: string
}

export interface ScheduleFilterDto {
  eventType: AnalyticEventTypes

  indexes?: string[] // -1 for events without index
}

export interface CreateScheduleDto {
  users?: Array<ScheduleUserDto>

  teamIds?: Array<string>

  cameraId?: string

  notificationTypes: AlertTypes[]

  filters: Array<ScheduleFilterDto>

  routines: Array<ScheduleRoutineDto>

  mode?: ScheduleMode
}

export interface ScheduleTime {
  hour: number
  minute: number
  second: number
}

export interface SchedulePeriod {
  id: string
  routineId: string
  startTime: ScheduleTime
  endTime: ScheduleTime
}

export interface ScheduleRoutine {
  id: string
  schId: string
  periods: Array<SchedulePeriod>
  date: Date
}

export interface ScheduleEntity {
  id: string
  users: Array<ScheduleUserEntity>
  teamIds: Array<string>
  workspaceId: string
  cameraId?: string
  notificationTypes: Array<AlertTypes>
  filters: Array<ScheduleFilterEntity>
  routines: Array<ScheduleRoutine>
  createdAt: Date
  mode: ScheduleMode
}

export interface UpdateScheduleDto {
  users?: Array<ScheduleUserDto>

  teamIds?: Array<string>

  cameraId?: string

  notificationTypes?: AlertTypes[]

  filters?: Array<ScheduleFilterDto>

  routines?: Array<ScheduleRoutineDto>

  mode: ScheduleMode
}

export interface ActivationScheduleDto {
  isActive: boolean
}
