import { Languages } from '@/lib/api'
import { useServices } from '@/lib/services'
import { useWorkspaceStore } from '@/modules/Workspace/store/useWorkspaceStore'
import { useAuthenticationStore } from '@/stores/authentication/useAuthenticationStore'
import { usePaymentStore } from '@/stores/payment/usePaymentStore'
import { useApplicationStore } from '@/stores/useApplicationStore'
import { storeToRefs } from 'pinia'

export async function createDefaultWorkspaces() {
  useApplicationStore().startLoading('createNewWorkspace')
  await createCustomerIfNotFound()
  const newWorkspace = await useServices().workspaceManager.workspace.create({
    name: 'My Space',
    description: "Connect Any Device, Secure Any Space. Because It's Powerful to Take Control.",
    language: Languages.english,
    welcomeMessage: 'welcome'
  })
  const workspaceId = newWorkspace.id
  localStorage.setItem('workspaceId', workspaceId)
  await useServices().workspaceManager.user.switchWorkspace(workspaceId)
  await useAuthenticationStore().reopenSessionSocket()
  await usePaymentStore().createTrialSubscription(newWorkspace.id)
  await useWorkspaceStore().fetchWorkspaces()
  useApplicationStore().stopLoading('createNewWorkspace')
}

async function createCustomerIfNotFound() {
  try {
    await useServices().stripePayment.customer.currentCustomer()
  } catch (e) {
    const { currentUser } = storeToRefs(useAuthenticationStore())
    if (currentUser.value?.user) {
      await useServices().stripePayment.customer.createCustomer({})
    }
  }
}
