import type { PlayerCore } from '@/player/lib/player/player-core'
import { type EventIdentity, EventManager } from '@/player/lib/analytic/event-manager'
import { ref } from 'vue'
import { AnalyticDetailedTyped, AnalyticEventTypes, type LatestEventDetail } from '@/lib/api'
import { type Analytic } from '@/lib/api/services/ai/analytics/analytic.interface'

export class AnalyticManager2 {
  public readonly analytics: EventManager[] = []
  public readonly activeAnalyticIndex = ref(-1)
  protected initPromise: Promise<void>
  protected initDoneCb!: () => void
  protected latestEvent: LatestEventDetail[] = []

  constructor(public readonly core: PlayerCore) {
    this.initPromise = new Promise((resolve) => (this.initDoneCb = resolve))
  }

  getLatestEvent(type: AnalyticEventTypes) {
    const item = this.latestEvent.find((e) => e.type === type)
    if (item) {
      return item.events
    } else return []
  }

  async init() {
    const [analytics, hardwareCrossline, latestEvent] = await Promise.all([
      this.core.apis.getAnalytics(),
      this.core.apis.crossLine(),
      this.core.apis.latestEvent()
    ])
    this.latestEvent = latestEvent
    if (this.getLatestEvent(AnalyticEventTypes.HwMotion).length > 0) {
      this.analytics.push(
        new EventManager(this.core, {
          type: AnalyticEventTypes.HwMotion,
          index: '0'
        })
      )
    }
    if (this.getLatestEvent(AnalyticEventTypes.HwAudio).length > 0) {
      this.analytics.push(
        new EventManager(this.core, {
          type: AnalyticEventTypes.HwAudio,
          index: '0'
        })
      )
    }
    if (this.getLatestEvent(AnalyticEventTypes.HwTampering).length > 0) {
      this.analytics.push(
        new EventManager(this.core, {
          type: AnalyticEventTypes.HwTampering,
          index: '0'
        })
      )
    }
    if (this.getLatestEvent(AnalyticEventTypes.HwBorderCrossing).length > 0) {
      this.analytics.push(
        new EventManager(this.core, {
          type: AnalyticEventTypes.HwBorderCrossing,
          index: '0'
        })
      )
    }
    if (hardwareCrossline && hardwareCrossline.enabled && hardwareCrossline.line) {
      this.analytics.push(
        new EventManager(this.core, {
          type: AnalyticEventTypes.HwBorderCrossing,
          index: '0',
          ref: {
            type: AnalyticEventTypes.HwBorderCrossing,
            id: '0',
            deleteAt: undefined,
            configs: {
              type: AnalyticEventTypes.HwBorderCrossing,
              config: {
                sensitivity: 1,
                minThreshold: 1,
                alarmDelay: 0,
                points: [
                  { x: hardwareCrossline.line.start.x, y: hardwareCrossline.line.start.y },
                  { x: hardwareCrossline.line.end.x, y: hardwareCrossline.line.end.y },
                  { x: hardwareCrossline.line.start.x, y: hardwareCrossline.line.start.y }
                ]
              },
              features: {
                usage: AnalyticDetailedTyped.crossLineBtoAWayHardware
              }
            }
          }
        })
      )
    } else if (this.getLatestEvent(AnalyticEventTypes.HwBorderCrossing).length > 0) {
      this.analytics.push(
        new EventManager(this.core, {
          type: AnalyticEventTypes.HwBorderCrossing,
          index: '0',
          ref: {
            type: AnalyticEventTypes.HwBorderCrossing,
            id: '0',
            deleteAt: undefined,
            configs: {
              type: AnalyticEventTypes.HwBorderCrossing,
              config: {
                sensitivity: 1,
                minThreshold: 1,
                alarmDelay: 0,
                points: []
              },
              features: {
                usage: AnalyticDetailedTyped.crossLineBtoAWayHardware
              }
            }
          }
        })
      )
    }
    for (const analytic of analytics) {
      this.analytics.push(
        new EventManager(this.core, {
          type: analytic.type,
          index: analytic.id,
          ref: analytic
        })
      )
    }
    if (this.initDoneCb) {
      this.initDoneCb()
    }
  }

  initWait() {
    return this.initPromise
  }

  getActiveAnalytic(): EventManager | undefined {
    if (this.activeAnalyticIndex.value >= 0) {
      const analytic = this.analytics[this.activeAnalyticIndex.value]
      if (analytic) {
        return analytic
      }
    }
  }

  enable(index: number) {
    this.activeAnalyticIndex.value = index
    this.onSoftSeek()
  }

  disable() {
    this.activeAnalyticIndex.value = -1
  }

  onSoftSeek() {
    const analytic = this.getActiveAnalytic()
    if (analytic) {
      analytic.updateForCurrentView()
    }
  }

  getAnalyticById(id: string): EventIdentity | undefined {
    return this.analytics.find((a) => a.identity.ref?.id === id)?.identity
  }

  updateAnalyticRef(id: string, data: Analytic) {
    const index = this.analytics.findIndex((a) => a.identity.ref?.id === id)
    if (this.analytics[index]) {
      this.analytics[index].identity.ref = data
    }
  }

  addAnalytic(analytic: Analytic) {
    this.analytics.push(
      new EventManager(this.core, {
        type: analytic.type,
        index: analytic.id,
        ref: analytic
      })
    )
  }

  removeAnalyticById(id: string) {
    const index = this.analytics.findIndex((a) => a.identity.ref?.id === id)
    if (index >= 0) {
      if (this.activeAnalyticIndex.value === index) {
        this.core.analyticHelper.disable()
      }
      const analytic = this.analytics[index]?.identity.ref
      if (analytic) {
        analytic.deleteAt = new Date().toISOString()
      }
      if (this.activeAnalyticIndex.value > index) {
        this.activeAnalyticIndex.value -= 1
      }
    }
  }

  static getDefaultUsage(type: AnalyticEventTypes): AnalyticDetailedTyped {
    switch (type) {
      case AnalyticEventTypes.None:
        return AnalyticDetailedTyped.unknown

      case AnalyticEventTypes.HwMotion:
        return AnalyticDetailedTyped.motionHardware

      case AnalyticEventTypes.HwTampering:
        return AnalyticDetailedTyped.tamperingHardware

      case AnalyticEventTypes.HwAudio:
        return AnalyticDetailedTyped.audioSuddenChangeHardware

      case AnalyticEventTypes.HwBorderCrossing:
        return AnalyticDetailedTyped.crossLineHardware

      case AnalyticEventTypes.SwMotion:
        return AnalyticDetailedTyped.motionSoftware

      case AnalyticEventTypes.SwFence:
        return AnalyticDetailedTyped.crossLineSoftware

      case AnalyticEventTypes.SwLeftMissed:
        return AnalyticDetailedTyped.missedObject

      default:
        return AnalyticDetailedTyped.unknown
    }
  }
}
