import { Service } from '@/lib/api'
import type {
  ActivationScheduleDto,
  CreateScheduleDto,
  ScheduleEntity,
  UpdateScheduleDto
} from '@/lib/api/services/ai/alert/alert.interface'

const isAIEnabledCache = new Map<string, Promise<boolean>>()
export class AlertService extends Service {
  create(data: CreateScheduleDto): Promise<ScheduleEntity> {
    return this.post<ScheduleEntity>('/api/alert-manager/schedule', data)
  }

  update(scheduleId: string, data: UpdateScheduleDto): Promise<ScheduleEntity> {
    return this.patch<ScheduleEntity>(`/api/alert-manager/schedule/${scheduleId}`, data)
  }

  getCameraSchedules(cameraId: string): Promise<Array<ScheduleEntity>> {
    return this.get<Array<ScheduleEntity>>(`/api/alert-manager/schedule/camera/${cameraId}`)
  }

  getCurrentUserSchedules(): Promise<Array<ScheduleEntity>> {
    return this.get<Array<ScheduleEntity>>(`/api/alert-manager/schedule/user/current`)
  }

  getCurrentWorkspaceSchedules(): Promise<Array<ScheduleEntity>> {
    return this.get<Array<ScheduleEntity>>(`/api/alert-manager/schedule/workspace`)
  }

  getUserSchedules(userId: string): Promise<Array<ScheduleEntity>> {
    return this.get<Array<ScheduleEntity>>(`/api/alert-manager/schedule/user/${userId}`)
  }

  updateUserScheduleStatus(scheduleId: string, data: ActivationScheduleDto) {
    return this.patch<Array<ScheduleEntity>>(
      `/api/alert-manager/schedule/${scheduleId}/user/activation`,
      data
    )
  }

  remove(scheduleId: string) {
    return this.deleteData<ScheduleEntity>(`/api/alert-manager/schedule/${scheduleId}`)
  }

  isAIEnabled(workspaceId: string): Promise<boolean> {
    if (!isAIEnabledCache.has(workspaceId)) {
      const res = this.get<boolean>(`/api/alert-manager/ai/workspace/${workspaceId}`)
      isAIEnabledCache.set(workspaceId, res)
    }
    return isAIEnabledCache.get(workspaceId) || Promise.resolve(false)
  }
}
